<template>
    <div>
        <ProductSearchPartial />

        <div class="section section--no-padding | u-margin-bottom">
            <div class="container">
                <div class="page-block | u-margin-bottom">
                    <div
                        class="u-margin-bottom">
                        <router-link
                            :to="{ name: 'shop.profile.orders' }"
                            class="page-block__breadcrumb-link"
                        >{{ trans('shop.profile.order.back_to_orders') }}</router-link>
                    </div>

                    <order-detail
                        :order="order"
                        :terms-and-conditions="termsAndConditions"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pageMixin from '../../mixins/page.js';
import ProductSearchPartial from '../../components/ProductSearchPartial.vue';
import OrderDetail from '../../components/profile/OrderDetail.vue';
import OrderCache from '../../cache/OrderCache.js';

export default {
    components: {
        ProductSearchPartial,
        OrderDetail
    },
    mixins: [pageMixin],
    metaInfo: {
        title: trans('page.profile.order.title')
    },
    /**
     * Stop the cache refresh.
     *
     * @param {object}   to
     * @param {object}   from
     * @param {Function} next
     */
    beforeRouteLeave(to, from, next) {
        this.orderCache.stopRefresh();
        next();
    },
    props: {
        orderId: {
            type: Number,
            default: null
        },
        termsAndConditions: {
            type: String,
            default: null
        }
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            orderCache: new OrderCache()
        };
    },
    computed: {
        ...mapGetters({
            getOrder: 'order/order'
        }),
        /**
         * Get the order.
         *
         * @return {object}
         */
        order() {
            return this.getOrder(this.orderId);
        }
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.scrollToTop();

        this.orderCache
            .getSingle({ key: 'id', value: this.orderId })
            .then(this.finishLoading)
            .catch(error => {
                console.error(error);
                console.trace();
            });
    }
};
</script>
