import Vue from 'vue';

import Router from 'vue-router';
import Meta from 'vue-meta';

import CategoryIndex from './pages/CategoryIndex.vue';
import CategoryDetail from './pages/CategoryDetail.vue';
import ProductDetail from './pages/ProductDetail.vue';

import CheckoutCart from './pages/checkout/CheckoutCart.vue';
import CheckoutShipping from './pages/checkout/CheckoutShipping.vue';
import CheckoutConfirmation from './pages/checkout/CheckoutConfirmation.vue';

import OrderConfirmation from './pages/OrderConfirmation.vue';

import NewDevelopmentsIndex from './pages/NewDevelopmentsIndex.vue';

import DirectoriesIndex from './pages/documents/DirectoriesIndex.vue';
import DocumentsIndex from './pages/documents/DocumentsIndex.vue';

import ProfileIndex from './pages/profile/ProfileIndex.vue';
import ProfileOrders from './pages/profile/ProfileOrders.vue';
import ProfileOrder from './pages/profile/ProfileOrder.vue';
import ProfileProducts from './pages/profile/ProfileProducts.vue';

import ErrorNotFound from './pages/ErrorNotFound.vue';
import ErrorUnauthorized from './pages/ErrorUnauthorized.vue';
import ErrorInternalServer from './pages/ErrorInternalServer.vue';

import Prop from './helpers/prop.js';

Vue.use(Router);
Vue.use(Meta);

let router = new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'login',
        },
        {
            path: '/account/password/reset/:token?',
            name: 'password.reset',
        },
        {
            path: '/account/password/set/:token?',
            name: 'password.set',
        },
        {
            path: '/shop',
            redirect: { name: 'category.index' },
        },
        {
            path: '/shop/home',
            name: 'category.index',
            component: CategoryIndex,
            props: {
                categories: window.categories,
                newProductImage: Prop.getProp('newProductImage'),
                newProductContent: Prop.getProp('newProductContent'),
            },
        },
        {
            path: '/shop/category/:categoryId',
            name: 'category.detail',
            component: CategoryDetail,
            props: (route) => ({
                categoryId: Number(route.params.categoryId),
                categories: window.categories,
            }),
        },
        {
            path: '/shop/category/:categoryId/subcategory/:subCategoryId',
            name: 'category.productDetail',
            component: ProductDetail,
            props: (route) => ({
                categoryId: Number(route.params.categoryId),
                subCategoryId: Number(route.params.subCategoryId),
                categories: window.categories,
            }),
        },
        {
            path: '/shop/category/:categoryId/subcategory/:subCategoryId/:productId',
            name: 'product.detail',
            component: ProductDetail,
            props: (route) => ({
                categoryId: Number(route.params.categoryId),
                subCategoryId: Number(route.params.subCategoryId),
                productId: route.params.productId,
                categories: window.categories,
            }),
        },
        {
            path: '/shop/cart',
            name: 'checkoutCart',
            component: CheckoutCart,
        },
        {
            path: '/shop/shipping',
            name: 'checkoutShipping',
            component: CheckoutShipping,
            props: {
                customer: window.customer || {},
                termsAndConditions: Prop.getProp('termsAndConditions'),
            },
        },
        {
            path: '/shop/confirmation',
            name: 'checkoutConfirmation',
            component: CheckoutConfirmation,
            props: {
                customer: window.customer || {},
                termsAndConditions: Prop.getProp('termsAndConditions'),
            },
        },
        {
            path: '/shop/order-confirmation',
            name: 'orderConfirmation',
            component: OrderConfirmation,
            props: {
                customer: window.customer || {},
            },
        },
        {
            path: '/shop/new-developments',
            name: 'newDevelopmentsIndex',
            component: NewDevelopmentsIndex,
            props: {
                newDevelopments: window.newDevelopments,
            },
        },
        {
            path: '/shop/documents/:parentDirectory?',
            name: 'directoriesIndex',
            component: DirectoriesIndex,
            props: () => ({
                mainDirectories: window.mainDirectories,
            }),
        },
        {
            path: '/shop/documents/:parentDirectory/files',
            name: 'documentsIndex',
            component: DocumentsIndex,
            props: (route) => ({
                mainDirectories: window.mainDirectories,
                parentDirectory: route.params.parentDirectory,
            }),
        },
        {
            path: '/shop/profile',
            name: 'shop.profile',
            component: ProfileIndex,
            props: {
                user: window.currentUser || {},
            },
        },
        {
            path: '/shop/profile/order',
            name: 'shop.profile.orders',
            component: ProfileOrders,
        },
        {
            path: '/shop/profile/order/:orderId',
            name: 'shop.profile.order',
            component: ProfileOrder,
            props: (route) => ({
                orderId: Number(route.params.orderId),
                termsAndConditions: Prop.getProp('termsAndConditions'),
            }),
        },
        {
            path: '/shop/profile/products',
            name: 'shop.profile.products',
            component: ProfileProducts,
        },
        {
            path: '/shop/403',
            name: 'error.403',
            component: ErrorUnauthorized,
        },
        {
            path: '/shop/404',
            name: 'error.404',
            component: ErrorNotFound,
        },
        {
            path: '/shop/500',
            name: 'error.500',
            component: ErrorInternalServer,
        },
        {
            path: '*',
            redirect: { name: 'error.404' },
        },
    ],
});

export default router;
