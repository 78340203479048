<template>
    <div>
        <h1 class="alpha | page-block__title">{{ trans('page.profile.order.heading') }} {{ order.order_number }}</h1>

        <h2 class="beta">{{ trans('shop.profile.order.ordered-products') }}</h2>
        <table class="table table--responsive table--product">
            <thead>
                <tr>
                    <td>{{ trans('shop.table.item-number') }}</td>
                    <td>{{ trans('shop.table.description') }}</td>
                    <td>{{ trans('shop.table.unit') }}</td>
                    <td>{{ trans('shop.table.start-price') }}</td>
                    <td>{{ trans('shop.table.quantity') }}</td>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(lineItem, index) in order.line_items"
                    :key="index"
                    :index="index"
                >
                    <td>{{ lineItem.product_external_id }}</td>
                    <td>{{ lineItem.product_name }}</td>
                    <td>{{ lineItem.unit }}</td>
                    <td class="u-text-right">
                        {{ lineItem.item_price | toEuro }}
                    </td>
                    <td>
                        {{ lineItem.quantity }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="u-margin-bottom u-text-right">
            <h3 class="u-font-normal">
                {{ trans('shop.profile.order.table-footer-text') }} <span class="u-font-price">{{ order.total_balance | toEuro }}</span>
            </h3>
        </div>

        <div class="u-font-tiny">
            <h3 class="u-font-normal | u-margin-bottom-narrow">{{ trans('shop.profile.order.shipping-invoicing-title') }}</h3>

            <div class="layout | u-margin-bottom">
                <div class="layout__item u-1/2-desk">
                    <div class="layout">
                        <div class="layout__item u-1/2-desk">
                            <h3>{{ trans('shop.profile.order.shipping-address') }}</h3>
                            <address v-if="order.shipping_address">
                                {{ order.shipping_address.name }}<br>
                                {{ order.shipping_address.street_address }}<br>
                                {{ order.shipping_address.zip_code }} {{ order.shipping_address.city }}<br>
                                {{ order.shipping_address.country }}
                            </address>
                            <address v-else>-</address>
                        </div><!--
                     --><div class="layout__item u-1/2-desk">
                            <h3>{{ trans('shop.profile.order.invoice-address') }}</h3>
                            <address v-if="order.billing_address">
                                {{ order.billing_address.name }}<br>
                                {{ order.billing_address.street_address }}<br>
                                {{ order.billing_address.zip_code }} {{ order.billing_address.city }}<br>
                                {{ order.billing_address.country }}
                            </address>
                            <address v-else>-</address>
                        </div>
                    </div>
                </div><!--
             --><div class="layout__item u-1/2-desk">
                    <div class="layout">
                        <div class="layout__item u-1/2-desk">
                            <div class="u-margin-bottom-narrow">
                                <h3>{{ trans('shop.profile.order.shipping-method') }}</h3>
                                <span>{{ order.delivery_mode }}</span>
                            </div>

                            <div class="u-margin-bottom-narrow">
                                <h3>{{ trans('shop.profile.order.order-status') }}</h3>
                                <span>{{ order.status }}</span>
                            </div>
                        </div><!--
                     --><div class="layout__item u-1/2-desk">
                            <div class="u-margin-bottom-narrow">
                                <h3>{{ trans('shop.profile.order.date-ordered') }}</h3>
                                <span>{{ order.ordered_date }}</span>
                            </div>

                            <div
                                v-if="order.shipping_date"
                                class="u-margin-bottom-narrow"
                            >
                                <h3>{{ trans('shop.profile.order.date-shipped') }}</h3>
                                <span>{{ order.shipping_date }}</span>
                            </div>

                            <div
                                v-if="order.packing_carrier"
                                class="u-margin-bottom-narrow"
                            >
                                <h3>{{ trans('shop.profile.order.shipping-carrier') }}</h3>
                                <span>{{ order.packing_carrier }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="u-margin-bottom">
            {{ trans('shop.wizard.confirmation.terms-text') }}
            <a
                v-if="termsAndConditions"
                :href="termsAndConditions"
                class="link-purple"
                target="_blank"
                rel="noopener noreferrer"
            >
                {{ trans('shop.wizard.confirmation.terms-link-text') }}
            </a>
            <span v-else>{{ trans('shop.wizard.terms-link-text') }}</span>
        </div>

        <div class="layout">
            <div class="layout__item u-1/2-desk">
                <h3 v-if="order.packing_slip_url || order.invoice_url">{{ trans('shop.profile.order.downloads') }}</h3>
                <ul class="list--unstyled">
                    <li
                        v-if="order.packing_slip_url"
                    >
                        <a
                            :href="order.packing_slip_url"
                            class="link-purple"
                        >
                            {{ trans('shop.profile.order.download-packing-slip') }}
                        </a>
                    </li>
                    <li
                        v-if="order.invoice_url"
                    >
                        <a
                            :href="order.invoice_url"
                            class="link-purple"
                        >
                            {{ trans('shop.profile.order.download-invoice') }}
                        </a>
                    </li>
                </ul>
            </div><!--
         --><div class="layout__item u-1/2-desk">
                <h3 v-if="order.notes">{{ trans('shop.profile.order.notes') }}</h3>
                {{ order.notes }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        order: {
            type: Object,
            default: () => {}
        },
        termsAndConditions: {
            type: String,
            default: null
        }
    }
};
</script>
