var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { dusk: "checkout-confirmation-component" } },
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section section--no-padding | u-margin-bottom" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "page-block page-block--wizard | u-margin-bottom",
              },
              [
                _c("WizardSteps", { attrs: { step: 3 } }),
                _vm._v(" "),
                _c("div", { staticClass: "wizard__step" }, [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "wizard__table-title | u-margin-bottom-narrow",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.trans("page.checkout.confirmation.title"))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("table", { staticClass: "table table--responsive" }, [
                    _c("thead", [
                      _c("tr", { staticClass: "u-vertical-align-baseline" }, [
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.item-number"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.description"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.unit"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.price"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.quantity"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.total-price"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.cartItems, function (cartItem, index) {
                        return _c("cart-item-row-partial", {
                          key: index,
                          attrs: { "cart-item": cartItem, editable: false },
                        })
                      }),
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "u-margin-bottom u-text-right" }, [
                    _c("h3", { staticClass: "wizard__table-title" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.trans(
                              "shop.wizard.confirmation.table-footer-text"
                            )
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "wizard__table-total-price" }, [
                        _vm._v(_vm._s(_vm._f("toEuro")(_vm.cartTotalPrice))),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "u-padding" }, [
                  _c("div", { staticClass: "wizard__notification" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.trans(
                            "shop.wizard.confirmation.notification-text"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "wizard__information" }, [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "wizard__table-title | u-margin-bottom-narrow",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.trans(
                              "shop.wizard.confirmation.shipping-invoicing"
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "layout  | u-margin-bottom" }, [
                      _c("div", { staticClass: "layout__item u-1/2-desk" }, [
                        _c(
                          "div",
                          { staticClass: "layout" },
                          _vm._l(
                            _vm.customer.addresses,
                            function (address, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "layout__item u-1/2-desk",
                                },
                                [
                                  _vm.customerAddressTitle(address)
                                    ? [
                                        _c(
                                          "h3",
                                          {
                                            staticClass: "wizard__small-title",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.customerAddressTitle(
                                                  address
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("address", [
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(address.name)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(address.street_address)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(address.zip_code) +
                                              " " +
                                              _vm._s(address.city)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(address.country) +
                                              "\n                                            "
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "layout__item u-1/2-desk" }, [
                        _c("div", { staticClass: "layout" }, [
                          _c(
                            "div",
                            { staticClass: "layout__item u-1/2-desk" },
                            [
                              _c(
                                "div",
                                { staticClass: "u-margin-bottom-narrow" },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "wizard__small-title" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "shop.wizard.confirmation.shipping-method"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.cartShipping.deliveryMode)
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.cartShipping.deliveryMode == "OTHER"
                                ? _c(
                                    "div",
                                    { staticClass: "u-margin-bottom-narrow" },
                                    [
                                      _c(
                                        "h4",
                                        { staticClass: "wizard__small-title" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.trans(
                                                "shop.wizard.confirmation.shipping-carrier"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "wizard__small-title | u-color-black",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.trans(
                                                "shop.wizard.shipping.carrier.name"
                                              )
                                            ) + ": "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.cartShipping.carrier)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "wizard__small-title | u-color-black",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.trans(
                                                "shop.wizard.shipping.carrier.phone-number"
                                              )
                                            ) + ":"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.cartShipping.phone)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "wizard__small-title | u-color-black",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.trans(
                                                "shop.wizard.shipping.carrier.email"
                                              )
                                            ) + ": "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.cartShipping.email)),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("h3", { staticClass: "wizard__small-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans(
                                      "shop.wizard.confirmation.po-number"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.cartShipping.poNumber || "-")
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "u-separator-line" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "layout | u-margin-bottom" }, [
                    _c("div", { staticClass: "layout__item u-1/2-desk" }, [
                      _vm.cartAcceptedTerms
                        ? _c("span", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.trans(
                                    "shop.wizard.confirmation.terms-text"
                                  )
                                ) +
                                "\n                                "
                            ),
                            _vm.termsAndConditions
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "wizard__link",
                                    attrs: {
                                      href: _vm.termsAndConditions,
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.trans(
                                            "shop.wizard.confirmation.terms-link-text"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("shop.wizard.terms-link-text")
                                    )
                                  ),
                                ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "layout__item u-1/2-desk" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.trans("shop.wizard.confirmation.notes"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.notes,
                            expression: "notes",
                          },
                        ],
                        staticClass: "field__input",
                        attrs: { name: "notes", rows: "4", cols: "50" },
                        domProps: { value: _vm.notes },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.notes = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "wizard__help-text" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.trans("shop.wizard.live-chat-text")) +
                        "\n                        "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "wizard__help-text-link",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.trans("shop.wizard.live-chat-contact")) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "u-separator-line" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "wizard__steps" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "checkoutShipping" } } },
                        [
                          _c("button", { staticClass: "btn btn--primary" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.trans("shop.wizard.navigation.step-2")
                                ) +
                                "\n                            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn--primary",
                          attrs: {
                            disabled: _vm.loading,
                            dusk: "place-order-button",
                          },
                          on: { click: _vm.placeOrder },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.trans("shop.wizard.navigation.finish")
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }