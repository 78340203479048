<template>
    <tr>
        <td class="u-text-center">
            <img
                :src="product.image"
                class="table__product-image"
            >
        </td>
        <td>{{ product.number }}</td>
        <td v-html="product.name"/>
        <td>{{ product.unit }}</td>
        <td class="u-text-right">
            {{ priceFromQuantity | toEuro }}
        </td>
        <td :class="{ 'has-error': !quantityIsValid }">
            <input
                v-model="quantity"
                :step="product.quantity_multiplier"
                name="quantity"
                type="number"
                min="0"
                max="99999"
                @keyup.enter="addProductQuantity"
            >
        </td>
    </tr>
</template>

<script>
import eventBus from '../../event-bus.js';
import { mapActions } from 'vuex';
import ProductMixin from '../../mixins/product.js';

export default {
    mixins: [ProductMixin],
    props: {
        product: {
            type: Object,
            default: () => {}
        }
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            quantity: null
        };
    },
    /**
     * Runs code after an instance is created.
     */
    created() {
        eventBus.$on('addProducts', this.addProductQuantity.bind(this));
    },
    methods: {
        ...mapActions({
            addItemToCart: 'cart/addItem'
        }),

        /**
         * Add the selected quantity to the cart.
         */
        addProductQuantity() {
            if (this.quantity > 0) {
                if (!this.quantityIsValid) {
                    eventBus.$emit('notification', {
                        type: 'error',
                        message: trans('message.error.invalid-quantity', {
                            quantityMultiplier: this.itemProduct.quantity_multiplier
                        })
                    });

                    return;
                }

                const cartItem = this.createCartItem({
                    product: this.product,
                    quantity: parseInt(this.quantity)
                });

                this.addItemToCart(cartItem);

                eventBus.$emit('addedProducts');

                this.quantity = null;
            }
        }
    }
};
</script>
