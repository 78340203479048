const state = {
    documents: [],
};

const getters = {
    documents: (state) => (directoryPath) =>
        state.documents.filter((documentItem) => documentItem.directoryPath == directoryPath),
};

const actions = {
    /**
     * Add all documents.
     *
     * @param {object} commit, state
     * @param {array} documents
     */
    addDocuments({ commit, state }, documents) {
        documents.forEach((documentItem) => {
            const existingItem = state.documents.find((item) => {
                return item.url == documentItem.url;
            });

            try {
                if (existingItem) {
                    commit('updateDocument', documentItem);
                } else {
                    commit('addDocument', documentItem);
                }
            } catch (error) {
                console.error(error);
                console.trace();
            }
        });
    },
};

const mutations = {
    /**
     * Add document to the documents state.
     *
     * @param {object} state
     * @param {object} documentItem
     */
    addDocument(state, documentItem) {
        if (typeof documentItem != 'object' || !documentItem.url) {
            throw {
                message: 'Mutation | Add document: document isnt an object',
                document: documentItem,
                type: typeof documentItem,
            };
        }

        state.documents.push(documentItem);
    },

    /**
     * Replace the current document object with a new document.
     *
     * @param {object} state
     * @param {object} documentItem
     */
    updateDocument(state, documentItem) {
        if (typeof documentItem != 'object' || !documentItem.url) {
            throw {
                message: 'Mutation | Update document: document isnt an object',
                document: documentItem,
                type: typeof documentItem,
            };
        }

        let existingItem = state.documents.find((item) => item.url == documentItem.url);

        existingItem = { ...documentItem };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
