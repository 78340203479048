var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "alpha | page-block__title" }, [
      _vm._v(
        _vm._s(_vm.trans("page.profile.order.heading")) +
          " " +
          _vm._s(_vm.order.order_number)
      ),
    ]),
    _vm._v(" "),
    _c("h2", { staticClass: "beta" }, [
      _vm._v(_vm._s(_vm.trans("shop.profile.order.ordered-products"))),
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table table--responsive table--product" }, [
      _c("thead", [
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.item-number")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.description")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.unit")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.start-price")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.quantity")))]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.order.line_items, function (lineItem, index) {
          return _c("tr", { key: index, attrs: { index: index } }, [
            _c("td", [_vm._v(_vm._s(lineItem.product_external_id))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(lineItem.product_name))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(lineItem.unit))]),
            _vm._v(" "),
            _c("td", { staticClass: "u-text-right" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm._f("toEuro")(lineItem.item_price)) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                    " +
                  _vm._s(lineItem.quantity) +
                  "\n                "
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-margin-bottom u-text-right" }, [
      _c("h3", { staticClass: "u-font-normal" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.trans("shop.profile.order.table-footer-text")) +
            " "
        ),
        _c("span", { staticClass: "u-font-price" }, [
          _vm._v(_vm._s(_vm._f("toEuro")(_vm.order.total_balance))),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-font-tiny" }, [
      _c("h3", { staticClass: "u-font-normal | u-margin-bottom-narrow" }, [
        _vm._v(
          _vm._s(_vm.trans("shop.profile.order.shipping-invoicing-title"))
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "layout | u-margin-bottom" }, [
        _c("div", { staticClass: "layout__item u-1/2-desk" }, [
          _c("div", { staticClass: "layout" }, [
            _c("div", { staticClass: "layout__item u-1/2-desk" }, [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.trans("shop.profile.order.shipping-address"))
                ),
              ]),
              _vm._v(" "),
              _vm.order.shipping_address
                ? _c("address", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.order.shipping_address.name)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.order.shipping_address.street_address)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.order.shipping_address.zip_code) +
                        " " +
                        _vm._s(_vm.order.shipping_address.city)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.order.shipping_address.country) +
                        "\n                        "
                    ),
                  ])
                : _c("address", [_vm._v("-")]),
            ]),
            _c("div", { staticClass: "layout__item u-1/2-desk" }, [
              _c("h3", [
                _vm._v(_vm._s(_vm.trans("shop.profile.order.invoice-address"))),
              ]),
              _vm._v(" "),
              _vm.order.billing_address
                ? _c("address", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.order.billing_address.name)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.order.billing_address.street_address)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.order.billing_address.zip_code) +
                        " " +
                        _vm._s(_vm.order.billing_address.city)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.order.billing_address.country) +
                        "\n                        "
                    ),
                  ])
                : _c("address", [_vm._v("-")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "layout__item u-1/2-desk" }, [
          _c("div", { staticClass: "layout" }, [
            _c("div", { staticClass: "layout__item u-1/2-desk" }, [
              _c("div", { staticClass: "u-margin-bottom-narrow" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.trans("shop.profile.order.shipping-method"))
                  ),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.order.delivery_mode))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "u-margin-bottom-narrow" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.trans("shop.profile.order.order-status"))),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.order.status))]),
              ]),
            ]),
            _c("div", { staticClass: "layout__item u-1/2-desk" }, [
              _c("div", { staticClass: "u-margin-bottom-narrow" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.trans("shop.profile.order.date-ordered"))),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.order.ordered_date))]),
              ]),
              _vm._v(" "),
              _vm.order.shipping_date
                ? _c("div", { staticClass: "u-margin-bottom-narrow" }, [
                    _c("h3", [
                      _vm._v(
                        _vm._s(_vm.trans("shop.profile.order.date-shipped"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.order.shipping_date))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.order.packing_carrier
                ? _c("div", { staticClass: "u-margin-bottom-narrow" }, [
                    _c("h3", [
                      _vm._v(
                        _vm._s(_vm.trans("shop.profile.order.shipping-carrier"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.order.packing_carrier))]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-margin-bottom" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.trans("shop.wizard.confirmation.terms-text")) +
          "\n        "
      ),
      _vm.termsAndConditions
        ? _c(
            "a",
            {
              staticClass: "link-purple",
              attrs: {
                href: _vm.termsAndConditions,
                target: "_blank",
                rel: "noopener noreferrer",
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.trans("shop.wizard.confirmation.terms-link-text")
                  ) +
                  "\n        "
              ),
            ]
          )
        : _c("span", [
            _vm._v(_vm._s(_vm.trans("shop.wizard.terms-link-text"))),
          ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "layout" }, [
      _c("div", { staticClass: "layout__item u-1/2-desk" }, [
        _vm.order.packing_slip_url || _vm.order.invoice_url
          ? _c("h3", [
              _vm._v(_vm._s(_vm.trans("shop.profile.order.downloads"))),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("ul", { staticClass: "list--unstyled" }, [
          _vm.order.packing_slip_url
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "link-purple",
                    attrs: { href: _vm.order.packing_slip_url },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.trans("shop.profile.order.download-packing-slip")
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.order.invoice_url
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "link-purple",
                    attrs: { href: _vm.order.invoice_url },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.trans("shop.profile.order.download-invoice")
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "layout__item u-1/2-desk" }, [
        _vm.order.notes
          ? _c("h3", [_vm._v(_vm._s(_vm.trans("shop.profile.order.notes")))])
          : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.order.notes) + "\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }