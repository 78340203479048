var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout tabs__content" }, [
    _c("div", { staticClass: "layout__item u-1/2-desk" }, [
      _c("h2", { staticClass: "beta | page-block__title" }, [
        _vm._v(_vm._s(_vm.trans("customer.profile.details"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("label", { staticClass: "field__label", attrs: { for: "name" } }, [
          _vm._v(_vm._s(_vm.trans("user.attributes.name"))),
        ]),
        _vm._v(" "),
        _c("p", { attrs: { id: "name" } }, [
          _vm._v(
            "\n                " + _vm._s(_vm.user.name) + "\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("label", { staticClass: "field__label", attrs: { for: "email" } }, [
          _vm._v(_vm._s(_vm.trans("user.attributes.email"))),
        ]),
        _vm._v(" "),
        _c("p", { attrs: { id: "email" } }, [
          _vm._v(
            "\n                " + _vm._s(_vm.user.email) + "\n            "
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "layout__item u-1/2-desk" }, [
      _c("h2", { staticClass: "beta | page-block__title" }, [
        _vm._v(_vm._s(_vm.trans("customer.profile.change_password"))),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.trans("user.info.change-password")))]),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c("passwordInput", {
            attrs: {
              error: _vm.form.errors.first("current_password"),
              label: _vm.trans("user.attributes.current_password"),
              name: "current_password",
            },
            model: {
              value: _vm.form.current_password,
              callback: function ($$v) {
                _vm.$set(_vm.form, "current_password", $$v)
              },
              expression: "form.current_password",
            },
          }),
          _vm._v(" "),
          _c("passwordInput", {
            attrs: {
              error: _vm.form.errors.first("new_password"),
              label: _vm.trans("user.attributes.new_password"),
              name: "new_password",
            },
            model: {
              value: _vm.form.new_password,
              callback: function ($$v) {
                _vm.$set(_vm.form, "new_password", $$v)
              },
              expression: "form.new_password",
            },
          }),
          _vm._v(" "),
          _c("passwordInput", {
            attrs: {
              error: _vm.form.errors.first("new_password_confirmation"),
              label: _vm.trans("user.attributes.new_password_confirmation"),
              name: "new_password_confirmation",
            },
            model: {
              value: _vm.form.new_password_confirmation,
              callback: function ($$v) {
                _vm.$set(_vm.form, "new_password_confirmation", $$v)
              },
              expression: "form.new_password_confirmation",
            },
          }),
          _vm._v(" "),
          _c("nav", { staticClass: "element" }, [
            _c(
              "button",
              {
                staticClass: "btn btn--primary",
                attrs: { disabled: _vm.form.processing, type: "submit" },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.trans("action.store")) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }