var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c("div", { staticClass: "section" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "page-block | u-margin-bottom" },
            [
              _c(
                "div",
                { staticClass: "page-block__header" },
                [
                  _c(
                    "div",
                    { staticClass: "page-block__breadcrumbs" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "page-block__breadcrumb-link",
                          attrs: { to: { name: "category.index" } },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.trans("page.shop.index.breadcrumb"))
                          ),
                        ]
                      ),
                      _vm._v(" » "),
                      _c(
                        "router-link",
                        {
                          staticClass: "page-block__breadcrumb-link",
                          attrs: {
                            to: {
                              name: "category.detail",
                              params: { categoryId: _vm.category.id },
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.category.name))]
                      ),
                      _vm._v(
                        " » " +
                          _vm._s(_vm.subCategory.name) +
                          "\n                    "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("category-menu", {
                    attrs: { "main-categories": _vm.categories },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "page-block__sub-header" }, [
                _c("div", {
                  staticClass: "page-block_subcategory-image-small | cover",
                  style: {
                    "background-image": "url(" + _vm.subCategory.image + ")",
                  },
                }),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticClass:
                      "alpha | page-block__title | u-margin-bottom-none",
                  },
                  [_vm._v(_vm._s(_vm.subCategory.name))]
                ),
              ]),
              _vm._v(" "),
              _vm.subCategory.description
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.subCategory.description),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("product-list", {
                attrs: {
                  title: _vm.trans("page.shop.products.title"),
                  products: _vm.products,
                  "product-id": _vm.productId,
                  loading: _vm.isLoading(),
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }