<template>
    <div
        v-if="newProductContent && newProductImage"
        :style="backgroundImage"

        class="section section--filled section--white section--promoted | cover"
    >
        <div
            v-if="newProductContent"
            class="container"
            v-html="newProductContent"
        />
    </div>
</template>

<script>
export default {
    props: {
        newProductContent: {
            type: String,
            default: null
        },
        newProductImage: {
            type: String,
            default: null
        }
    },
    computed: {
        /**
         * Check if there is a background image.
         *
         * @return {object}
         */
        backgroundImage() {
            if (!this.newProductImage) {
                return;
            }

            return { backgroundImage: 'url(' + this.newProductImage + ')' };
        }
    }
};
</script>
