var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section section--no-padding | u-margin-bottom" },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "page-block | u-margin-bottom" }, [
              _c("h1", { staticClass: "alpha | page-block__title" }, [
                _vm._v(_vm._s(_vm.trans("page.profile.product.heading"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tabs" },
                [
                  _c("profile-tabs"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tabs__content" },
                    [
                      _c("product-list", {
                        attrs: {
                          products: _vm.products,
                          loading: _vm.isLoading(),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }