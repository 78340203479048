<template>
    <div>
        <div class="page-header__cart | u-display--none-portable">
            <div class="layout | u-flex-center">
                <div class="layout__item u-1/5-desk">
                    <svg class="icon icon--medium | u-display--block">
                        <use xlink:href="#icon-cart-white"/>
                    </svg>
                </div><!--
             --><div class="layout__item u-4/5-desk">
                    <a
                        :href="route('shop.cart')"
                        title="Check-out >"
                        class="u-uppercase"
                    >
                        {{ trans('shop.header.checkout') }}
                    </a>
                    <div class="page-header__cart-info">
                        <span class="cart-tot-items">{{ cartTotalItems }}</span>
                        {{ trans('shop.header.articles') }}
                        <span>{{ cartTotalPrice | toEuro }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="u-flex-center u-display--none-desk">
            <svg class="icon icon--medium | u-display--block">
                <use xlink:href="#icon-cart-white"/>
            </svg>
            <div>
                <a
                    :href="route('shop.cart')"
                    title="Check-out >"
                    class="u-uppercase"
                >
                    {{ trans('shop.header.checkout') }}
                </a>
                <div class="page-header__cart-info">
                    <span class="cart-tot-items">{{ cartTotalItems }}</span>
                    {{ trans('shop.header.articles') }}
                    <span>{{ cartTotalPrice | toEuro }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            cartTotalPrice: 'cart/totalPrice',
            cartTotalItems: 'cart/totalItems'
        })
    }
};
</script>
