var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.products
    ? _c("div", [
        _c("div", { staticClass: "product-add-bar" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filter,
                expression: "filter",
              },
            ],
            staticClass:
              "field__input field__input--filter | u-margin-bottom-none",
            attrs: {
              placeholder: _vm.trans("shop.product.filter-placeholder"),
              name: "filterProduct",
              type: "text",
            },
            domProps: { value: _vm.filter },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.filter = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.productAdded
                  ? _c("span", {
                      staticClass: "product-add-bar__message",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.trans("shop.product.added", {
                            cart_url: _vm.route("shop.cart"),
                          })
                        ),
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn--small-flex btn--primary",
                  attrs: {
                    type: "button",
                    dusk: "add-products-button",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addProducts.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("shop.product.add-to-cart")) +
                      "\n                "
                  ),
                  _c(
                    "svg",
                    {
                      staticClass:
                        "product-add-bar__icon | icon icon--medium | u-margin-left-narrow",
                    },
                    [_c("use", { attrs: { "xlink:href": "#icon-cart-white" } })]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "beta" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("table", { staticClass: "table table--responsive table--product" }, [
          _c("thead", [
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.image")))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.item-number")))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.description")))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.unit")))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.trans("shop.table.start-price")))]),
              _vm._v(" "),
              _c("td", { staticClass: "u-td-single-line" }, [
                _vm._v(_vm._s(_vm.trans("shop.table.quantity"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.filteredProducts, function (product, index) {
              return _c(
                "product-row-partial",
                _vm._b(
                  { key: index, attrs: { product: product } },
                  "product-row-partial",
                  product,
                  false
                )
              )
            }),
            1
          ),
        ]),
        _vm._v(" "),
        _vm.loading
          ? _c("div", [_vm._v(_vm._s(_vm.trans("shop.table.loading-records")))])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }