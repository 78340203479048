<template>
    <div class="container">
        <h1 class="alpha | u-text-color-primary u-text-center">{{ trans('page-error.forbidden-access.heading') }}</h1>
        <p
            class="u-text-center"
        >
            {{ trans('page-error.forbidden-access.description') }}
        </p>
    </div>
</template>

<script>
import pageMixin from '../mixins/page.js';

export default {
    mixins: [pageMixin],
    metaInfo: {
        title: trans('page-error.forbidden-access.title')
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.finishLoading();
    }
};
</script>
