import textInput from './text.vue';
import searchWidget from './search.vue';
import passwordInput from './password.vue';

const components = {
    textInput,
    searchWidget,
    passwordInput,
};

components.install = (Vue) => {
    for (const componentName in components) {
        const component = components[componentName];

        if (component && componentName !== 'install') {
            Vue.component(component.name, component);
        }
    }
};

export default components;
