<template>
    <div>
        <ProductSearchPartial />

        <div class="section section--no-padding | u-margin-bottom">
            <div class="container">
                <div class="page-block | u-text-center u-flex-column-center u-margin-bottom">
                    <span class="u-margin-bottom">
                        {{ trans('shop.order-confirmation.message') }}
                    </span>
                    <span
                        v-if="customer.blocked"
                        class="u-margin-bottom"
                    >
                        {{ trans('shop.order-confirmation.blocked') }}
                    </span>
                    <router-link
                        :to="{ name: 'category.index' }"
                        class="btn btn--primary"
                    >
                        {{ trans('shop.order-confirmation.button-text') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '../mixins/page.js';

import ProductSearchPartial from '../components/ProductSearchPartial.vue';

export default {
    components: {
        ProductSearchPartial
    },
    mixins: [pageMixin],
    props: {
        customer: {
            type: Object,
            default: () => {}
        }
    },
    metaInfo: {
        title: trans('page.order-confirmation.title')
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.finishLoading();
        this.scrollToTop();
    }
};
</script>
