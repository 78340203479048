<template>
    <tr>
        <td>
            <a
                :href="cartItem.product.category_page_url"
                class="wizard__link"
            >{{ cartItem.productNumber }}</a>
        </td>
        <td>{{ cartItem.name }}</td>
        <td>{{ cartItem.unit }}</td>
        <td v-if="editable">{{ priceTier }}</td>
        <td>{{ cartItem.price | toEuro }}</td>
        <td
            v-if="editable"
            :class="{ 'has-error': !quantityIsValid }"
        >
            <input
                v-model="quantity"
                :step="cartItem.product.quantity_multiplier"
                class="field__input field__input--smaller"
                name="quantity"
                type="number"
                min="0"
                max="99999"
                @input="updateQuantity"
            >
        </td>
        <td v-else>{{ cartItem.quantity }}</td>
        <td>{{ cartItem.totalPrice | toEuro }}</td>
        <td v-if="editable">
            <a
                class="wizard__link | u-cursor-pointer"
                dusk="remove-products-button"
                @click="removeItem(cartItem)"
            >
                {{ trans('action.delete') }}
            </a>
        </td>
    </tr>
</template>

<script>
import { mapActions } from 'vuex';
import ProductMixin from '../../mixins/product.js';

export default {
    mixins: [ProductMixin],
    props: {
        cartItem: {
            type: Object,
            default: null
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            quantity: this.cartItem.quantity
        };
    },
    methods: {
        ...mapActions({
            removeItem: 'cart/removeItem',
            updateItemQuantity: 'cart/updateItemQuantity',
            updateValidQuantity: 'cart/updateValidQuantity'
        }),
        /**
         * Update the quantity in the store.
         *
         * @param {object} event
         */
        updateQuantity(event) {
            const quantity = parseInt(event.target.value);

            this.updateValidQuantity(this.quantityIsValid);

            if (quantity > 0 && this.quantityIsValid) {
                this.updateItemQuantity({
                    item: this.cartItem,
                    quantity: quantity
                });
            }
        }
    }
};
</script>
