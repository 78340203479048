var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section section--no-padding | u-margin-bottom" },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "page-block | u-margin-bottom" }, [
              _c("h1", { staticClass: "alpha | page-block__title" }, [
                _vm._v(_vm._s(_vm.trans("shop.new-developments.title"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid" },
                _vm._l(_vm.newDevelopments, function (newDevelopment) {
                  return _c(
                    "div",
                    {
                      key: newDevelopment.order,
                      staticClass:
                        "grid__item grid__item--new-developments | u-relative",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "new-developments__content | cover",
                          style:
                            "background-image: url(" +
                            newDevelopment.thumbnail +
                            ");",
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: newDevelopment.pdf,
                                target: "_blank",
                                rel: "noopener noreferrer",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "new-developments__overlay" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.trans(
                                          "shop.new-developments.download"
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }