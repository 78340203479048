<template>
    <div>
        <ProductSearchPartial />

        <div class="section section--no-padding | u-margin-bottom">
            <div class="container">
                <div class="page-block page-block--wizard | u-margin-bottom">
                    <WizardSteps :step="2" />

                    <div class="wizard__step">
                        <div class="layout | u-margin-bottom">
                            <div class="layout__item">
                                <h3 class="u-margin-bottom-narrow">{{ trans('shop.wizard.shipping.title') }}</h3>

                                <div class="layout">
                                    <div class="layout__item u-1/3-desk | u-margin-bottom-narrow">
                                        <h2 class="u-margin-bottom-narrow">{{ trans('shop.wizard.shipping.choose-method') }}</h2>
                                        <p>
                                            <input
                                                id="psm1"
                                                v-model="form.deliveryMode"
                                                name="preferredShippingMethod"
                                                value="KEYSTONE"
                                                type="radio">
                                            <label for="psm1">
                                                {{ trans('shop.wizard.shipping.method.keystone') }}
                                            </label>
                                            <a
                                                v-tooltip.right="trans('shop.wizard.tooltip.keystone')"
                                                href="#"
                                                class="wizard__info"
                                            >
                                                <img
                                                    alt="info-icon"
                                                    src="/dist/front/images/info-icon.png">
                                            </a>
                                        </p>
                                        <p>
                                            <input
                                                id="psm2"
                                                v-model="form.deliveryMode"
                                                name="preferredShippingMethod"
                                                value="OTHER"
                                                type="radio">
                                            <label for="psm2">
                                                {{ trans('shop.wizard.shipping.method.own-transport') }}
                                            </label>
                                            <a
                                                v-tooltip.right="trans('shop.wizard.tooltip.own-transport')"
                                                href="#"
                                                class="wizard__info"
                                            >
                                                <img
                                                    alt="info-icon"
                                                    src="/dist/front/images/info-icon.png">
                                            </a>
                                        </p>
                                        <p>
                                            <input
                                                id="psm3"
                                                v-model="form.deliveryMode"
                                                name="preferredShippingMethod"
                                                value="PICKUP"
                                                type="radio">
                                            <label for="psm3">
                                                {{ trans('shop.wizard.shipping.method.pick-up') }}
                                            </label>
                                            <a
                                                v-tooltip.right="trans('shop.wizard.tooltip.pick-up')"
                                                href="#"
                                                class="wizard__info"
                                            >
                                                <img
                                                    alt="info-icon"
                                                    src="/dist/front/images/info-icon.png">
                                            </a>
                                        </p>
                                        <label
                                            class="field__label"
                                            for="PurchaseOrderFormNum"
                                        >
                                            {{ trans('shop.wizard.shipping.po-number') }}
                                        </label>
                                        <input
                                            id="PurchaseOrderFormNum"
                                            v-model="form.poNumber"
                                            class="field__input"
                                            name="PurchaseOrderFormNum"
                                            maxlength="20"
                                            type="text"
                                        >
                                    </div><!--
                                 --><div class="layout__item u-1/3-desk">
                                        <div v-show="form.deliveryMode === 'OTHER'">
                                            <h2 class="u-margin-bottom-narrow">
                                                {{ trans('shop.wizard.shipping.carrier.title') }}
                                            </h2>

                                            <label
                                                class="field__label"
                                                for="shippingCarrier"
                                            >
                                                {{ trans('shop.wizard.shipping.carrier.name') }}
                                            </label>
                                            <input
                                                id="shippingCarrier"
                                                v-model="form.carrier"
                                                class="field__input"
                                                name="shippingCarrier"
                                                placeholder="Name"
                                                type="text"
                                            >

                                            <label
                                                class="field__label"
                                                for="shippingCarrierPhone"
                                            >
                                                {{ trans('shop.wizard.shipping.carrier.phone-number') }}
                                            </label>
                                            <input
                                                id="shippingCarrierPhone"
                                                v-model="form.phone"
                                                class="field__input"
                                                name="shippingCarrierPhone"
                                                placeholder="Phone number"
                                                type="text"
                                            >

                                            <label
                                                class="field__label"
                                                for="shippingCarrierEmail"
                                            >
                                                {{ trans('shop.wizard.shipping.carrier.email') }}
                                            </label>
                                            <input
                                                id="shippingCarrierEmail"
                                                v-model="form.email"
                                                class="field__input"
                                                name="shippingCarrierEmail"
                                                placeholder="E-mail address"
                                                type="text"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="layout | u-margin-bottom">
                            <div
                                v-for="(address, index) in customer.addresses"
                                :key="index"
                                class="layout__item u-1/3-desk"
                            >
                                <template v-if="customerAddressTitle(address)">
                                    <h3>{{ customerAddressTitle(address) }}</h3>
                                    <address>
                                        {{ address.name }}<br>
                                        {{ address.street_address }}<br>
                                        {{ address.zip_code }} {{ address.city }}<br>
                                        {{ address.country }}
                                    </address>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="u-padding">
                        <div class="wizard__help-text">
                            {{ trans('shop.wizard.live-chat-text') }}
                            <a
                                class="wizard__help-text-link"
                                href="#" >
                                {{ trans('shop.wizard.live-chat-contact') }}
                            </a>
                        </div>

                        <div class="u-separator-line"/>

                        <p class="u-text-right">
                            <input
                                id="terms"
                                v-model="termsAccepted"
                                name="terms"
                                class="xstyled"
                                type="checkbox"
                            >
                            <label for="terms">
                                <span>
                                    {{ trans('shop.wizard.terms-text') }}
                                    <a
                                        v-if="termsAndConditions"
                                        :href="termsAndConditions"
                                        class="wizard__link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {{ trans('shop.wizard.terms-link-text') }}
                                    </a>
                                    <span v-else>{{ trans('shop.wizard.terms-link-text') }}</span>
                                </span>
                            </label>
                        </p>

                        <div class="wizard__steps">
                            <router-link
                                :to="{ name: 'checkoutCart' }"
                            >
                                <button class="btn btn--primary">
                                    {{ trans('shop.wizard.navigation.step-1') }}
                                </button>
                            </router-link>
                            <button
                                :disabled="!acceptedTerms || !form.deliveryMode"
                                class="btn btn--primary"
                                @click="$router.push({ name: 'checkoutConfirmation' })"
                            >
                                {{ trans('shop.wizard.navigation.step-3') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ApiMixin from '../../mixins/api.js';
import pageMixin from '../../mixins/page.js';
import customerMixin from '../../mixins/customer.js';

import WizardSteps from '../../components/checkout/WizardSteps.vue';
import ProductSearchPartial from '../../components/ProductSearchPartial.vue';

export default {
    components: {
        WizardSteps,
        ProductSearchPartial
    },
    mixins: [pageMixin, ApiMixin, customerMixin],
    props: {
        termsAndConditions: {
            type: String,
            default: null
        },
        customer: {
            type: Object,
            default: () => {}
        }
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            form: {
                deliveryMode: null,
                carrier: null,
                phone: null,
                email: null,
                poNumber: null
            },
            termsAccepted: false
        };
    },
    metaInfo: {
        title: trans('page.checkout.shipping.title')
    },
    computed: {
        ...mapGetters({
            shipping: 'cart/shipping',
            acceptedTerms: 'cart/acceptedTerms'
        })
    },
    watch: {
        form: {
            /**
             * Process the changed shipping information in the form.
             *
             * @param {object} form
             */
            handler(form) {
                this.setShipping(form);
            },
            deep: true
        },
        /**
         * Check if the terms are accepted.
         *
         * @param {boolean} termsAccepted
         */
        termsAccepted(termsAccepted) {
            this.acceptTerms(termsAccepted);
        }
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.finishLoading();
        this.scrollToTop();
        if (this.shipping.deliveryMode) {
            this.form = this.shipping;
            this.termsAccepted = this.acceptedTerms;
        }
    },
    methods: {
        ...mapActions({
            setShipping: 'cart/setShipping',
            acceptTerms: 'cart/acceptTerms'
        })
    }
};
</script>
