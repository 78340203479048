<template>
    <div v-if="products">
        <div class="product-add-bar">
            <input
                v-model="filter"

                :placeholder="trans('shop.product.filter-placeholder')"
                class="field__input field__input--filter | u-margin-bottom-none"
                name="filterProduct"
                type="text"
            >

            <div>
                <transition name="fade">
                    <span
                        v-if="productAdded"
                        class="product-add-bar__message"
                        v-html="trans('shop.product.added', {cart_url: route('shop.cart')})"
                    />
                </transition>

                <button
                    type="button"
                    class="btn btn--small-flex btn--primary"
                    dusk="add-products-button"
                    aria-label="Close"

                    @click.prevent="addProducts"
                >
                    {{ trans('shop.product.add-to-cart') }}
                    <svg class="product-add-bar__icon | icon icon--medium | u-margin-left-narrow">
                        <use xlink:href="#icon-cart-white"/>
                    </svg>
                </button>
            </div>
        </div>

        <h2 class="beta">{{ title }}</h2>

        <table class="table table--responsive table--product">
            <thead>
                <tr>
                    <td>{{ trans('shop.table.image') }}</td>
                    <td>{{ trans('shop.table.item-number') }}</td>
                    <td>{{ trans('shop.table.description') }}</td>
                    <td>{{ trans('shop.table.unit') }}</td>
                    <td>{{ trans('shop.table.start-price') }}</td>
                    <td class="u-td-single-line">{{ trans('shop.table.quantity') }}</td>
                </tr>
            </thead>
            <tbody>
                <product-row-partial
                    v-for="(product, index) in filteredProducts"
                    v-bind="product"
                    :key="index"
                    :product="product"
                />
            </tbody>
        </table>
        <div v-if="loading">{{ trans('shop.table.loading-records') }}</div>
    </div>
</template>
<script>
import eventBus from '../../event-bus.js';
import ProductRowPartial from './ProductRowPartial.vue';

export default {
    components: {
        ProductRowPartial
    },
    props: {
        title: {
            type: String,
            default: null
        },
        products: {
            type: Array,
            default: () => []
        },
        productId: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            productAdded: false,
            filter: ''
        };
    },

    computed: {
        /**
         * Get the products, filtered by the filter.
         *
         * @return {array}
         */
        filteredProducts() {
            if (!this.filter.length) {
                return this.products;
            }

            return this.products.filter(
                product =>
                    product.number.toLowerCase().includes(this.filter.toLowerCase()) ||
                    product.name.toLowerCase().includes(this.filter.toLowerCase())
            );
        }
    },

    /**
     * Runs code after an instance is mounted.
     */
    mounted() {
        if (this.productId) {
            this.filter = this.productId;
        }

        eventBus.$on('addedProducts', this.addedProductsMessage.bind(this));
    },

    methods: {
        /**
         * Throw an event to trigger adding products.
         */
        addProducts() {
            eventBus.$emit('addProducts');
        },

        /**
         * Show the message that products are added.
         */
        addedProductsMessage() {
            let component = this;

            this.productAdded = true;

            setTimeout(() => {
                component.productAdded = false;
            }, 3000);
        }
    }
};
</script>
