import Vue from 'vue';

import './bootstrap.js';

import MainApp from './MainApp.vue';
import AppNotifications from './components/AppNotifications';
import router from './router.js';
import store from './store/index.js';
import StorageControl from './store/storageControl.js';
import eventBus from './event-bus.js';

import PageHeader from './components/PageHeader.vue';
import PageHeaderMobile from './components/PageHeaderMobile.vue';
import CartInfo from './components/checkout/CartInfo.vue';

router.beforeEach((to, from, next) => {
    eventBus.$emit('clearNotification');
    next();
});

/**
 * Initialize Vue.
 */
window.app = new Vue({
    el: '.js-vue',
    router: router,
    store: store,
    components: {
        PageHeader,
        PageHeaderMobile,
        CartInfo,
        MainApp,
        AppNotifications,
    },
    data: {
        version: 0.5,
    },
});

StorageControl.check(window.app.version);
