var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-center" },
    _vm._l(_vm.notifications, function (notification, index) {
      return _c("BaseAlert", {
        key: index,
        attrs: {
          index: index,
          type: notification.type,
          message: notification.message,
          removable: true,
        },
        on: {
          remove: function ($event) {
            return _vm.remove(index)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }