var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section section--no-padding | u-margin-bottom" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass:
                  "page-block | u-text-center u-flex-column-center u-margin-bottom",
              },
              [
                _c("span", { staticClass: "u-margin-bottom" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.trans("shop.order-confirmation.message")) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _vm.customer.blocked
                  ? _c("span", { staticClass: "u-margin-bottom" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.trans("shop.order-confirmation.blocked")) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn--primary",
                    attrs: { to: { name: "category.index" } },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.trans("shop.order-confirmation.button-text")
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }