<template>
    <div dusk="checkout-confirmation-component">
        <ProductSearchPartial />

        <div class="section section--no-padding | u-margin-bottom">
            <div class="container">
                <div class="page-block page-block--wizard | u-margin-bottom">
                    <WizardSteps :step="3" />

                    <div class="wizard__step">
                        <h3 class="wizard__table-title | u-margin-bottom-narrow">{{ trans('page.checkout.confirmation.title') }}</h3>

                        <table class="table table--responsive">
                            <thead>
                                <tr class="u-vertical-align-baseline">
                                    <td>{{ trans('shop.table.item-number') }}</td>
                                    <td>{{ trans('shop.table.description') }}</td>
                                    <td>{{ trans('shop.table.unit') }}</td>
                                    <td>{{ trans('shop.table.price') }}</td>
                                    <td>{{ trans('shop.table.quantity') }}</td>
                                    <td>{{ trans('shop.table.total-price') }}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <cart-item-row-partial
                                    v-for="(cartItem, index) in cartItems"
                                    :key="index"
                                    :cart-item="cartItem"
                                    :editable="false"
                                />
                            </tbody>
                        </table>
                        <div class="u-margin-bottom u-text-right">
                            <h3 class="wizard__table-title">
                                {{ trans('shop.wizard.confirmation.table-footer-text') }} <span class="wizard__table-total-price">{{ cartTotalPrice | toEuro }}</span>
                            </h3>
                        </div>
                    </div>

                    <div class="u-padding">
                        <div class="wizard__notification">
                            {{ trans('shop.wizard.confirmation.notification-text') }}
                        </div>

                        <div class="wizard__information">
                            <h3 class="wizard__table-title | u-margin-bottom-narrow">{{ trans('shop.wizard.confirmation.shipping-invoicing') }}</h3>

                            <div class="layout  | u-margin-bottom">
                                <div class="layout__item u-1/2-desk">
                                    <div class="layout">
                                        <div
                                            v-for="(address, index) in customer.addresses"
                                            :key="index"
                                            class="layout__item u-1/2-desk"
                                        >
                                            <template v-if="customerAddressTitle(address)">
                                                <h3 class="wizard__small-title">{{ customerAddressTitle(address) }}</h3>
                                                <address>
                                                    {{ address.name }}<br>
                                                    {{ address.street_address }}<br>
                                                    {{ address.zip_code }} {{ address.city }}<br>
                                                    {{ address.country }}
                                                </address>
                                            </template>
                                        </div>
                                    </div>
                                </div><!--
                             --><div class="layout__item u-1/2-desk">
                                    <div class="layout">
                                        <div class="layout__item u-1/2-desk">
                                            <div class="u-margin-bottom-narrow">
                                                <h3 class="wizard__small-title">{{ trans('shop.wizard.confirmation.shipping-method') }}</h3>
                                                <span>{{ cartShipping.deliveryMode }}</span>
                                            </div>

                                            <div
                                                v-if="cartShipping.deliveryMode == 'OTHER'"
                                                class="u-margin-bottom-narrow"
                                            >
                                                <h4 class="wizard__small-title">{{ trans('shop.wizard.confirmation.shipping-carrier') }}</h4>

                                                <h3 class="wizard__small-title | u-color-black">{{ trans('shop.wizard.shipping.carrier.name') }}: </h3>
                                                <span>{{ cartShipping.carrier }}</span>
                                                <h3 class="wizard__small-title | u-color-black">{{ trans('shop.wizard.shipping.carrier.phone-number') }}:</h3>
                                                <span>{{ cartShipping.phone }}</span>
                                                <h3 class="wizard__small-title | u-color-black">{{ trans('shop.wizard.shipping.carrier.email') }}: </h3>
                                                <span>{{ cartShipping.email }}</span>
                                            </div>

                                            <h3 class="wizard__small-title">{{ trans('shop.wizard.confirmation.po-number') }}</h3>
                                            <span>{{ cartShipping.poNumber || '-' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="u-separator-line"/>

                        <div class="layout | u-margin-bottom">
                            <div class="layout__item u-1/2-desk">
                                <span v-if="cartAcceptedTerms">
                                    {{ trans('shop.wizard.confirmation.terms-text') }}
                                    <a
                                        v-if="termsAndConditions"
                                        :href="termsAndConditions"
                                        class="wizard__link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {{ trans('shop.wizard.confirmation.terms-link-text') }}
                                    </a>
                                    <span v-else>{{ trans('shop.wizard.terms-link-text') }}</span>
                                </span>
                            </div><!--
                         --><div class="layout__item u-1/2-desk">
                                <p>{{ trans('shop.wizard.confirmation.notes') }}</p>
                                <textarea
                                    v-model="notes"
                                    name="notes"
                                    rows="4"
                                    cols="50"
                                    class="field__input"
                                />
                            </div>
                        </div>

                        <div class="wizard__help-text">
                            {{ trans('shop.wizard.live-chat-text') }}
                            <a
                                class="wizard__help-text-link"
                                href="#" >
                                {{ trans('shop.wizard.live-chat-contact') }}
                            </a>
                        </div>

                        <div class="u-separator-line"/>

                        <div class="wizard__steps">
                            <router-link
                                :to="{ name: 'checkoutShipping' }"
                            >
                                <button class="btn btn--primary">
                                    {{ trans('shop.wizard.navigation.step-2') }}
                                </button>
                            </router-link>
                            <button
                                :disabled="loading"
                                class="btn btn--primary"
                                dusk="place-order-button"
                                @click="placeOrder"
                            >
                                {{ trans('shop.wizard.navigation.finish') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

import config from '../../config.js';
import eventBus from '../../event-bus.js';

import { toEuro } from '../../helpers/currency.js';

import ApiMixin from '../../mixins/api.js';
import pageMixin from '../../mixins/page.js';
import customerMixin from '../../mixins/customer.js';

import WizardSteps from '../../components/checkout/WizardSteps.vue';
import ProductSearchPartial from '../../components/ProductSearchPartial.vue';
import CartItemRowPartial from '../../components/checkout/CartItemRowPartial.vue';

export default {
    components: {
        WizardSteps,
        ProductSearchPartial,
        CartItemRowPartial
    },
    mixins: [pageMixin, ApiMixin, customerMixin],
    props: {
        customer: {
            type: Object,
            default: () => {}
        },
        termsAndConditions: {
            type: String,
            default: null
        }
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            notes: null
        };
    },
    metaInfo: {
        title: trans('page.checkout.confirmation.title')
    },
    computed: {
        ...mapGetters({
            cartShipping: 'cart/shipping',
            cartAcceptedTerms: 'cart/acceptedTerms',
            cartNotes: 'cart/notes',
            cartItems: 'cart/items',
            cartTotalPrice: 'cart/totalPrice'
        }),
        /**
         * Check if the minimum price has been reached.
         *
         * @return {boolean}
         */
        reachedMinimumPrice() {
            return this.cartTotalPrice >= config.minimumPrice;
        }
    },
    watch: {
        /**
         * Watch note changes, and store in the vuex store.
         *
         * @param {string} notes
         */
        notes(notes) {
            this.setNotes(notes);
        }
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.notes = this.cartNotes;
        if (!this.reachedMinimumPrice) {
            eventBus.$emit('notification', {
                type: 'warning',
                message: trans('message.minimum-price', {
                    minimum: toEuro(config.minimumPrice),
                    home_url: route('shop.index')
                })
            });
        }

        this.finishLoading();
        this.scrollToTop();
    },
    methods: {
        ...mapActions({
            setNotes: 'cart/setNotes',
            resetCart: 'cart/reset'
        }),

        /**
         * Places an order when the "Finish" button is pressed.
         */
        placeOrder() {
            let items = [];

            this.startLoading();

            items = this.cartItems.map(cartItem => {
                let item = { ...cartItem };

                delete item.product;
                item.product_id = cartItem.product.id;

                return item;
            });

            axios
                .post('/shop/order/add', {
                    items: items,
                    shipping: this.cartShipping,
                    totalPrice: this.cartTotalPrice,
                    acceptedTerms: this.cartAcceptedTerms,
                    notes: this.cartNotes
                })
                .then(() => {
                    this.resetCart();
                    this.finishLoading();
                    this.$router.push({ name: 'orderConfirmation' });
                })
                .catch(error => ApiMixin.methods.handleError(error));
        }
    }
};
</script>
