import moment from 'moment';

/**
 * Sets the moment locale
 *
 * @param {string} locale
 */
export function setLocale(locale) {
    moment.locale(locale);
}

/**
 * Returns a date from a w3c date string.
 *
 * @param {string} w3cDate
 *
 * @return {string}
 */
export function getDateFromW3c(w3cDate) {
    if (!w3cDate) {
        return '';
    }

    return moment(w3cDate).format('L');
}

/**
 * Returns a time from a w3c date string.
 *
 * @param {string} w3cDate
 *
 * @return {string}
 */
export function getTimeFromW3c(w3cDate) {
    if (!w3cDate) {
        return '';
    }

    return moment(w3cDate).format('LT');
}

/**
 * Returns a date and time from a w3c date string.
 *
 * @param {string} w3cDate
 *
 * @return {string}
 */
export function getDateTimeFromW3c(w3cDate) {
    if (!w3cDate) {
        return '';
    }

    return moment(w3cDate).format('LLLL');
}
