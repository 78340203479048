var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", { staticClass: "u-text-center" }, [
      _c("img", {
        staticClass: "table__product-image",
        attrs: { src: _vm.product.image },
      }),
    ]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.product.number))]),
    _vm._v(" "),
    _c("td", { domProps: { innerHTML: _vm._s(_vm.product.name) } }),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.product.unit))]),
    _vm._v(" "),
    _c("td", { staticClass: "u-text-right" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm._f("toEuro")(_vm.priceFromQuantity)) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("td", { class: { "has-error": !_vm.quantityIsValid } }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.quantity,
            expression: "quantity",
          },
        ],
        attrs: {
          step: _vm.product.quantity_multiplier,
          name: "quantity",
          type: "number",
          min: "0",
          max: "99999",
        },
        domProps: { value: _vm.quantity },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.addProductQuantity.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.quantity = $event.target.value
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }