import BaseCache from './BaseCache.js';

/**
 * Product cache, handle the sync with the server, and cache the results.
 *
 * TODO: bij KEYSHO-215 kun je in deze functie de functionaliteit maken voor de verschillende pagina's.
 * Mocht het generiek kunnen, en je het vaker wil kunnen gebruiken, dan in de base. (mogelijk voor orders?)
 */
class ProductCache extends BaseCache {
    /**
     * Set the variables.
     *
     * @param {object} options
     */
    constructor(options = {}) {
        super({
            name: 'product_' + options.categoryId + '_' + options.subCategoryId,
            route: route('shop.products', { category: options.categoryId, subCategory: options.subCategoryId }),
            store: 'products/addProducts',
        });
    }
}

export default ProductCache;
