// https://tc39.github.io/ecma262/#sec-string.prototype.includes
if (!String.prototype.includes) {
    Object.defineProperty(String.prototype, 'includes', {
        /**
         * Get the value.
         *
         * @param {string} search
         * @param {number} start
         *
         * @return {boolean}
         */
        value: function (search, start) {
            let startPosition = 0;

            if (typeof start == 'number') {
                startPosition = start;
            }

            if (startPosition + search.length > this.length) {
                return false;
            } else {
                return this.indexOf(search, startPosition) !== -1;
            }
        },
    });
}
