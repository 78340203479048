var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "page-header__nav-responsive" },
    [
      _c(
        "router-link",
        {
          staticClass: "page-header__nav-item",
          attrs: { to: { name: "category.index" }, tag: "li" },
        },
        [
          _c("a", { staticClass: "page-header__nav-link-responsive" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.trans("shop.header.menu.home")) +
                "\n        "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "page-header__nav-item",
          attrs: { to: { name: "directoriesIndex" }, tag: "li" },
        },
        [
          _c("a", { staticClass: "page-header__nav-link-responsive" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.trans("shop.header.menu.documents")) +
                "\n        "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "page-header__nav-item",
          attrs: { to: { name: "newDevelopmentsIndex" }, tag: "li" },
        },
        [
          _c("a", { staticClass: "page-header__nav-link-responsive" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.trans("shop.header.menu.new-developments")) +
                "\n        "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }