export default {
    methods: {
        /**
         * Get the address title.
         *
         * @param {object} address
         *
         * @return {string}
         */
        customerAddressTitle(address) {
            const addressType = address.type.toLowerCase();
            const validTypes = ['shipping', 'invoice'];

            if (!validTypes.includes(addressType)) {
                return;
            }

            return trans('shop.wizard.' + addressType + '-address');
        },
    },
};
