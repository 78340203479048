const state = {
    directories: [],
};

const getters = {
    directories: (state) => (parentPath) =>
        state.directories.filter((directory) => directory.relativePath.indexOf(parentPath + '/') == 0),
};

const actions = {
    /**
     * Add all directories.
     *
     * @param {object} commit
     * @param {array} directories
     */
    addDirectories({ commit, state }, directories) {
        directories.forEach((directory) => {
            const existingItem = state.directories.find((item) => {
                return item.relativePath == directory.relativePath;
            });

            try {
                if (existingItem) {
                    commit('updateDirectory', directory);
                } else {
                    commit('addDirectory', directory);
                }
            } catch (error) {
                console.error(error);
                console.trace();
            }
        });
    },
};

const mutations = {
    /**
     * Add directory to the directories state.
     *
     * @param {object} state
     * @param {object} directory
     */
    addDirectory(state, directory) {
        if (typeof directory != 'object' || !directory.relativePath) {
            throw {
                message: 'Mutation | Add directory: directory isnt an object',
                directory: directory,
                type: typeof directory,
            };
        }

        state.directories.push(directory);
    },

    /**
     * Replace the current directory object with a new directory.
     *
     * @param {object} state
     * @param {object} directory
     */
    updateDirectory(state, directory) {
        if (typeof directory != 'object' || !directory.relativePath) {
            throw {
                message: 'Mutation | Update directory: directory isnt an object',
                directory: directory,
                type: typeof directory,
            };
        }

        let existingItem = state.directories.find((item) => item.relativePath == directory.relativePath);

        existingItem = { ...directory };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
