<template>
    <div class="layout tabs__content">
        <div class="layout__item u-1/2-desk">
            <h2 class="beta | page-block__title">{{ trans('customer.profile.details') }}</h2>

            <div class="field">
                <label
                    for="name"
                    class="field__label"
                >{{ trans('user.attributes.name') }}</label>
                <p id="name">
                    {{ user.name }}
                </p>
            </div>

            <div class="field">
                <label
                    for="email"
                    class="field__label"
                >{{ trans('user.attributes.email') }}</label>
                <p id="email">
                    {{ user.email }}
                </p>
            </div>
        </div><!--
     --><div class="layout__item u-1/2-desk">
            <h2 class="beta | page-block__title">{{ trans('customer.profile.change_password') }}</h2>

            <p>{{ trans('user.info.change-password') }}</p>

            <form
                @submit.prevent="onSubmit"
            >
                <passwordInput
                    v-model="form.current_password"

                    :error="form.errors.first('current_password')"
                    :label="trans('user.attributes.current_password')"

                    name="current_password"
                />

                <passwordInput
                    v-model="form.new_password"

                    :error="form.errors.first('new_password')"
                    :label="trans('user.attributes.new_password')"

                    name="new_password"
                />

                <passwordInput
                    v-model="form.new_password_confirmation"

                    :error="form.errors.first('new_password_confirmation')"
                    :label="trans('user.attributes.new_password_confirmation')"

                    name="new_password_confirmation"
                />

                <nav class="element">
                    <button
                        :disabled="form.processing"
                        type="submit"
                        class="btn btn--primary"
                    >
                        {{ trans('action.store') }}
                    </button>
                </nav>
            </form>
        </div>
    </div>
</template>
<script>
import Form from 'form-backend-validation';
import formMixin from '../../mixins/form.js';
import eventBus from '../../event-bus.js';

export default {
    mixins: [formMixin],
    props: {
        user: {
            type: Object,
            default: () => {}
        }
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            form: new Form({
                current_password: null,
                new_password: null,
                new_password_confirmation: null
            })
        };
    },
    methods: {
        /**
         * Validate the form.
         */
        onSubmit() {
            this.form
                .put(route('shop.profile.update'))
                .then(() => {
                    eventBus.$emit('notification', {
                        type: 'success',
                        message: trans('message.data-saved')
                    });
                })
                .catch(() => {
                    this.scrollToFirstError();
                });
        }
    }
};
</script>
