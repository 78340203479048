var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "u-no-hover-underline",
      attrs: {
        to: {
          name: "category.detail",
          params: { categoryId: _vm.category.id },
        },
      },
    },
    [
      _c("div", { staticClass: "category-menu__item" }, [
        _vm.category.image
          ? _c("img", {
              staticClass: "category-menu__item-image",
              attrs: { src: _vm.category.image },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass:
              "beta category-menu__item-title | u-margin-bottom-none",
          },
          [_vm._v("\n            " + _vm._s(_vm.category.name) + "\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }