import { sortObjectArrayBy } from '../../helpers/array.js';

const state = {
    orders: [],
};

const getters = {
    order: (state) => (orderId) => state.orders.find((order) => order.id == orderId),
    orders: (state) => state.orders.sort((first, second) => sortObjectArrayBy(second, first, 'ordered_date_w3c')),
};

const actions = {
    /**
     * Add all orders.
     *
     * @param {object} commit
     * @param {array} orders
     */
    addOrders({ commit }, orders) {
        commit('setItems', orders);
    },
};

const mutations = {
    /**
     * Add all orders.
     *
     * @param {object} state
     * @param {array} orders
     */
    setItems(state, orders) {
        state.orders = orders;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
