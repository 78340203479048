import Vue from 'vue';
import Vuex from 'vuex';
import cart from './modules/cart';
import order from './modules/order';
import history from './modules/history';
import products from './modules/products';
import directories from './modules/directories';
import documents from './modules/documents';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        cart,
        order,
        history,
        products,
        directories,
        documents,
    },
    plugins: [
        /**
         * TODO: KEYSHO-229 Fix the security issue
         */
        createPersistedState({
            storage: window.localStorage,
            paths: ['cart'],
        }),
    ],
});
