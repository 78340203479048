import ProductPriceService from '../services/productPrice.js';

export default {
    computed: {
        /**
         * Get the product or cart item.
         *
         * @return {object}
         */
        itemProduct() {
            if (this.cartItem) {
                return this.cartItem.product;
            } else if (this.product) {
                return this.product;
            }
        },

        /**
         * Get the product price tiers.
         *
         * @return {array}
         */
        priceTiers() {
            return this.itemProduct.price;
        },

        /**
         * Get current price tier in a nice format
         *
         * @return {string}
         */
        priceTier() {
            return ProductPriceService.getItemPriceTierRange({
                priceTiers: this.priceTiers,
                quantity: this.quantity,
            });
        },

        /**
         * Get the correct price depending on the selected quantity
         *
         * @return {number}
         */
        priceFromQuantity() {
            return ProductPriceService.getItemPrice({ priceTiers: this.priceTiers, quantity: this.quantity });
        },

        /**
         * Check if the quantity is valid.
         *
         * @return {boolean}
         */
        quantityIsValid() {
            if (this.quantity < 0) {
                return false;
            }

            if (!this.itemProduct.quantity_multiplier) {
                return true;
            }

            return this.quantity % this.itemProduct.quantity_multiplier == 0;
        },
    },
    methods: {
        /**
         * Get the item for the cart by product and quantity.
         *
         * @param {object} product, quantity
         *
         * @return {object}
         */
        createCartItem({ product, quantity }) {
            return {
                product: product,
                productNumber: product.number,
                name: product.name,
                unit: product.unit,
                quantity: quantity,
                price: 0,
                totalPrice: 0,
            };
        },
    },
};
