var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field", class: _vm.stateClasses },
    [
      _vm.label
        ? _c(
            "label",
            { staticClass: "field__label", attrs: { for: _vm.name } },
            [
              _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
              _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "input",
        _vm._b(
          {
            staticClass: "field__input field__input--search",
            attrs: { id: _vm.name, name: _vm.name, type: "text" },
            domProps: { value: _vm.newValue },
            on: { input: _vm.onInput },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _vm.error
        ? [
            _c("span", { staticClass: "field__message" }, [
              _vm._v(_vm._s(_vm.error)),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }