var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "section section--no-padding | u-padding-top-narrow u-margin-bottom-narrow",
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "field-search | u-relative" },
          [
            _c("searchWidget", {
              attrs: {
                placeholder: _vm.trans("shop.product-search.placeholder"),
                endpoint: "/shop/product-search",
                "query-parameter-name": "query",
              },
              model: {
                value: _vm.searchValue,
                callback: function ($$v) {
                  _vm.searchValue = $$v
                },
                expression: "searchValue",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }