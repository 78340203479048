const state = {
    products: [],
};

const getters = {
    products: (state) => (categoryId) =>
        state.products.filter((product) => product.price.length && product.category_id == categoryId),
};

const actions = {
    /**
     * Add all products.
     *
     * @param {object} commit, state
     * @param {array} products
     */
    addProducts({ commit, state }, products) {
        products.forEach((product) => {
            const existingItem = state.products.find((item) => item.id == product.id);

            try {
                if (existingItem) {
                    commit('updateProduct', product);
                } else {
                    commit('addProduct', product);
                }
            } catch (error) {
                console.error(error);
                console.trace();
            }
        });
    },
};

const mutations = {
    /**
     * Add product to the products state.
     *
     * @param {object} state
     * @param {object} product
     */
    addProduct(state, product) {
        if (typeof product != 'object' || !product.id) {
            throw { message: 'Mutation | Add product: product isnt an object', product: product };
        }

        state.products.push(product);
    },

    /**
     * Replace the current product object with a new product.
     *
     * @param {object} state
     * @param {object} product
     */
    updateProduct(state, product) {
        if (typeof product != 'object' || !product.id) {
            throw { message: 'Mutation | Add product: product isnt an object', product: product };
        }

        let existingItem = state.products.find((item) => item.id == product.id);

        existingItem = { ...product };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
