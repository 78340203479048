<template>
    <div>
        <product-search-partial />

        <sub-category-partial
            v-if="category"
            :categories="category.subCategories"
            :main-categories="categories"
            :category="category"
        />
    </div>
</template>

<script>
import ApiMixin from '../mixins/api.js';
import pageMixin from '../mixins/page.js';

import SubCategoryPartial from '../components/SubCategoryPartial.vue';
import ProductSearchPartial from '../components/ProductSearchPartial.vue';

export default {
    components: {
        SubCategoryPartial,
        ProductSearchPartial
    },
    mixins: [pageMixin, ApiMixin],
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        categoryId: {
            type: Number,
            default: null
        }
    },
    metaInfo: {
        title: trans('page.shop.category.title')
    },
    computed: {
        /**
         * Get the category id.
         *
         * @return {number}
         */
        category() {
            return this.categories.find(category => category.id == this.categoryId);
        }
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.finishLoading();
        this.scrollToTop();
    }
};
</script>
