<template>
    <div class="section section--categories section--purple-light">
        <div class="container">
            <div class="grid grid--center">
                <div
                    v-for="(category, index) in categories"
                    :key="index"
                    class="grid__item grid__item--category"
                >
                    <router-link
                        :to="{ name: 'category.detail', params: { categoryId: category.id } }"
                        class="u-no-hover-underline"
                    >
                        <div class="category-block">
                            <img
                                v-if="category.image"
                                :src="category.image"
                                class="page-block_category-image | u-margin-bottom-narrow"
                            >
                            <h2 class="beta | u-margin-bottom-none u-margin-top-auto">{{ category.name }}</h2>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        categories: {
            type: Array,
            default: () => []
        }
    }
};
</script>
