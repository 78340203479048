var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "page-background-image": _vm.$route.path !== "/shop/home" },
      style: _vm.backgroundImageStyle,
    },
    [
      _c("TopProgress", { ref: "topProgress" }),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }