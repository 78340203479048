var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section section--no-padding | u-margin-bottom" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "page-block | u-margin-bottom" },
              [
                _c(
                  "div",
                  { staticClass: "u-margin-bottom" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "page-block__breadcrumb-link",
                        attrs: { to: { name: "shop.profile.orders" } },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.trans("shop.profile.order.back_to_orders"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("order-detail", {
                  attrs: {
                    order: _vm.order,
                    "terms-and-conditions": _vm.termsAndConditions,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }