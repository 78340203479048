// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        /**
         * Value.
         *
         * @param {array} searchElement
         * @param {number} fromIndex
         *
         * @return {bool}
         */
        value: function (searchElement, fromIndex) {
            var o;
            var len;
            var n;
            var k;
            // 1. Let O be ? ToObject(this value).

            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            if (n >= 0) {
                k = Math.max(n, 0);
            } else {
                k = Math.max(len - Math.abs(n), 0);
            }

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                // c. Increase k by 1.
                // NOTE: === provides the correct "SameValueZero" comparison needed here.
                if (o[k] === searchElement) {
                    return true;
                }
                k++;
            }

            // 8. Return false
            return false;
        },
    });
}
