<template>
    <div>
        <ProductSearchPartial />

        <div class="section">
            <div class="container">
                <div class="page-block | u-margin-bottom">
                    <div
                        class="u-margin-bottom">
                        <router-link
                            :to="{ name: 'category.index' }"
                            class="page-block__breadcrumb-link"
                        >
                            {{ trans('page.shop.index.breadcrumb') }}
                        </router-link>
                        &nbsp;»&nbsp;
                        <router-link
                            :to="{ name: 'directoriesIndex', params: documentBreadcrumbParameters }"
                            class="page-block__breadcrumb-link"
                        >
                            {{ trans('page.documents.title') }}
                        </router-link>
                        &nbsp;»&nbsp;
                        {{ parentDirectoryName }}
                    </div>

                    <h1 class="alpha | page-block__title">{{ parentDirectoryName }}</h1>

                    <input
                        v-model="filter"

                        :placeholder="trans('shop.documents.table.filter-placeholder')"
                        class="field__input field__input--filter"
                        name="filterDocument"
                        type="text"
                    >

                    <h2 class="beta">{{ trans('shop.documents.table.title') }}</h2>

                    <table class="table table--responsive table--documents">
                        <thead>
                            <tr>
                                <td>{{ trans('shop.documents.table.name') }}</td>
                                <td>{{ trans('shop.documents.table.download') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(documentItem, index) in filteredDocuments"
                                :key="index"
                            >
                                <td>{{ documentItem.filename }}</td>
                                <td>
                                    <a
                                        :href="documentItem.url"
                                        :download="documentItem.filename"
                                        class="link-purple"
                                    >
                                        {{ trans('shop.documents.table.download') }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="isLoading()">{{ trans('shop.table.loading-records') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pageMixin from '../../mixins/page.js';
import ProductSearchPartial from '../../components/ProductSearchPartial.vue';
import DocumentCache from '../../cache/DocumentCache.js';

export default {
    components: {
        ProductSearchPartial
    },
    mixins: [pageMixin],
    props: {
        mainDirectories: {
            type: Array,
            default: () => []
        },
        parentDirectory: {
            type: String,
            default: ''
        }
    },
    metaInfo: {
        title: trans('page.documents.title')
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            filter: '',
            documentCache: new DocumentCache({ parentDirectory: this.parentDirectory })
        };
    },
    computed: {
        ...mapGetters({
            getDocuments: 'documents/documents'
        }),

        /**
         * Get the documents of the current directory.
         *
         * @return {array}
         */
        documents() {
            return this.getDocuments(this.parentDirectory);
        },

        /**
         * Get documents filtered by filename.
         *
         * @return {array}
         */
        filteredDocuments() {
            if (!this.filter.length) {
                return this.documents;
            }

            return this.documents.filter(documentItem =>
                documentItem.filename.toLowerCase().includes(this.filter.toLowerCase())
            );
        },

        /**
         * Gets the name of the parent directory.
         *
         * @return {string}
         */
        parentDirectoryName() {
            return this.parentDirectory.substr(this.parentDirectory.lastIndexOf('/') + 1);
        },

        /**
         * Gets the name of the grandparent directory.
         *
         * @return {string}
         */
        grandParentDirectoryName() {
            return this.parentDirectory.substr(0, this.parentDirectory.indexOf('/'));
        },

        /**
         * Gets the parameters for the document breadcrumb.
         *
         * @return {object}
         */
        documentBreadcrumbParameters() {
            const index = this.mainDirectories.findIndex(directory => directory.name === this.grandParentDirectoryName);

            if (0 == index) {
                return {};
            }

            return { parentDirectory: this.grandParentDirectoryName };
        }
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.scrollToTop();

        this.documentCache
            .getAll()
            .then(this.finishLoading)
            .catch(error => {
                console.error(error);
                console.trace();
            });
    }
};
</script>
