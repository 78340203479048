<template>
    <div
        :class="{ 'page-background-image': $route.path !=='/shop/home' }"
        :style="backgroundImageStyle"
    >
        <TopProgress ref="topProgress"/>

        <div class="clearfix">
            <router-view/>
        </div>
    </div>
</template>

<script>
import { vueTopprogress as TopProgress } from 'vue-top-progress';

export default {
    components: {
        TopProgress
    },
    props: {
        backgroundImage: {
            type: String,
            default: null
        }
    },
    computed: {
        /**
         * Check if there is a background image.
         *
         * @return {object}
         */
        backgroundImageStyle() {
            if (this.backgroundImage && this.$route.path !== '/shop/home') {
                return { backgroundImage: 'url(' + this.backgroundImage + ')' };
            }

            return {};
        }
    }
};
</script>
