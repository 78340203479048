import BaseCache from './BaseCache.js';

/**
 * Order cache, handle the sync with the server, and cache the results.
 */
class OrderCache extends BaseCache {
    /**
     * Set the variables.
     */
    constructor() {
        super({
            name: 'order',
            route: route('shop.profile.orders.json'),
            store: 'order/addOrders',
        });
    }
}

export default OrderCache;
