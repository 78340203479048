<template>
    <div>
        <ProductSearchPartial />

        <div class="section section--no-padding | u-margin-bottom">
            <div class="container">
                <div class="page-block | u-margin-bottom">
                    <h1 class="alpha | page-block__title">{{ trans('page.profile.product.heading') }}</h1>

                    <div class="tabs">
                        <profile-tabs/>
                        <div class="tabs__content">
                            <product-list
                                :products="products"
                                :loading="isLoading()"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pageMixin from '../../mixins/page.js';
import ProductSearchPartial from '../../components/ProductSearchPartial.vue';
import ProductList from '../../components/product/ProductList.vue';
import ProfileTabs from '../../components/profile/ProfileTabs.vue';
import HistoryCache from '../../cache/HistoryCache.js';

export default {
    components: {
        ProductSearchPartial,
        ProductList,
        ProfileTabs,
        HistoryCache
    },
    mixins: [pageMixin],
    metaInfo: {
        title: trans('shop.profile.tabs.product-history')
    },
    /**
     * Stop the cache refresh.
     *
     * @param {object}   to
     * @param {object}   from
     * @param {Function} next
     */
    beforeRouteLeave(to, from, next) {
        this.historyCache.stopRefresh();
        next();
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            historyCache: new HistoryCache()
        };
    },
    computed: {
        ...mapGetters({
            products: 'history/products'
        })
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.scrollToTop();

        this.historyCache
            .getAll()
            .then(this.finishLoading)
            .catch(error => {
                console.error(error);
                console.trace();
            });
    }
};
</script>
