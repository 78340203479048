<template>
    <router-link
        :to="{ name: 'category.detail', params: { categoryId: category.id } }"
        class="u-no-hover-underline"
    >
        <div
            class="category-menu__item">
            <img
                v-if="category.image"
                :src="category.image"
                class="category-menu__item-image"
            >
            <h2 class="beta category-menu__item-title | u-margin-bottom-none">
                {{ category.name }}
            </h2>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        category: {
            type: Object,
            default: () => {},
            required: true
        }
    }
};
</script>
