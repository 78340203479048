<template>
    <div class="layout | wizard | u-margin-bottom u-margin-left-flush">
        <div
            :class="{'is-active': step == 1}"
            class="layout__item u-1/3-portable u-1/3-desk | wizard__item"
        >
            <span class="wizard__title">
                <router-link :to="{ name: 'checkoutCart' }">
                    <span class="u-display--none-desk">
                        #1
                    </span>
                    <span class="u-display--none-portable">
                        {{ trans('page.checkout.cart.heading') }}
                    </span>
                </router-link>
            </span>
        </div><!--
     --><div
             :class="{'is-active': step == 2}"
             class="layout__item u-1/3-portable u-1/3-desk | wizard__item"
         >
             <span class="wizard__title">
                <router-link :to="{ name: 'checkoutShipping' }">
                    <span class="u-display--none-desk">
                        #2
                    </span>
                    <span class="u-display--none-portable">
                        {{ trans('page.checkout.shipping.heading') }}
                    </span>
                </router-link>
             </span>
        </div><!--
     --><div
            :class="{'is-active': step == 3}"
            class="layout__item u-1/3-portable u-1/3-desk | wizard__item"
        >
        <span class="wizard__title">
            <button
                :disabled="!acceptedTerms"
                class="btn btn--link"
                @click="$router.push({ name: 'checkoutConfirmation' })"
            >
                <span class="u-display--none-desk">
                    #3
                </span>
                <span class="u-display--none-portable">
                    {{ trans('page.checkout.confirmation.heading') }}
                </span>
            </button>
        </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        step: {
            type: Number,
            default: 1
        }
    },
    computed: {
        ...mapGetters({
            acceptedTerms: 'cart/acceptedTerms'
        })
    }
};
</script>
