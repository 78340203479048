/**
 * Prop...
 */
class Prop {
    /**
     * Get a prop.
     *
     * @param {string} dataAttribute
     * @param {boolean} isJson
     *
     * @return {Array}
     */
    static getProp(dataAttribute, isJson) {
        let element = document.querySelector('.js-vue');

        if (!element) {
            return [];
        }

        if (isJson) {
            return JSON.parse(element.dataset[dataAttribute]);
        }

        return element.dataset[dataAttribute];
    }
}

export default Prop;
