var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h1", { staticClass: "alpha | u-text-color-primary u-text-center" }, [
      _vm._v(_vm._s(_vm.trans("page-error.not-found.heading"))),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "u-text-center" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.trans("page-error.not-found.description")) +
          "\n    "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }