var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-header__cart | u-display--none-portable" }, [
      _c("div", { staticClass: "layout | u-flex-center" }, [
        _c("div", { staticClass: "layout__item u-1/5-desk" }, [
          _c("svg", { staticClass: "icon icon--medium | u-display--block" }, [
            _c("use", { attrs: { "xlink:href": "#icon-cart-white" } }),
          ]),
        ]),
        _c("div", { staticClass: "layout__item u-4/5-desk" }, [
          _c(
            "a",
            {
              staticClass: "u-uppercase",
              attrs: { href: _vm.route("shop.cart"), title: "Check-out >" },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.trans("shop.header.checkout")) +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page-header__cart-info" }, [
            _c("span", { staticClass: "cart-tot-items" }, [
              _vm._v(_vm._s(_vm.cartTotalItems)),
            ]),
            _vm._v(
              "\n                    " +
                _vm._s(_vm.trans("shop.header.articles")) +
                "\n                    "
            ),
            _c("span", [_vm._v(_vm._s(_vm._f("toEuro")(_vm.cartTotalPrice)))]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-flex-center u-display--none-desk" }, [
      _c("svg", { staticClass: "icon icon--medium | u-display--block" }, [
        _c("use", { attrs: { "xlink:href": "#icon-cart-white" } }),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "a",
          {
            staticClass: "u-uppercase",
            attrs: { href: _vm.route("shop.cart"), title: "Check-out >" },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.trans("shop.header.checkout")) +
                "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "page-header__cart-info" }, [
          _c("span", { staticClass: "cart-tot-items" }, [
            _vm._v(_vm._s(_vm.cartTotalItems)),
          ]),
          _vm._v(
            "\n                " +
              _vm._s(_vm.trans("shop.header.articles")) +
              "\n                "
          ),
          _c("span", [_vm._v(_vm._s(_vm._f("toEuro")(_vm.cartTotalPrice)))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }