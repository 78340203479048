var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c("div", { staticClass: "section" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "page-block | u-margin-bottom" },
            [
              _c(
                "div",
                { staticClass: "u-margin-bottom" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "page-block__breadcrumb-link",
                      attrs: { to: { name: "category.index" } },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.trans("page.shop.index.breadcrumb")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n                     » \n                    " +
                      _vm._s(_vm.trans("page.documents.title")) +
                      "\n                "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h1", { staticClass: "alpha | page-block__title" }, [
                _vm._v(_vm._s(_vm.trans("shop.documents.title"))),
              ]),
              _vm._v(" "),
              _c("directory-tabs", {
                attrs: { directories: _vm.mainDirectories },
              }),
              _vm._v(" "),
              !_vm.isLoading()
                ? _c(
                    "div",
                    { staticClass: "grid" },
                    _vm._l(_vm.directories, function (directory, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "grid__item grid__item--document",
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "link-purple | u-no-hover-underline",
                              attrs: {
                                to: {
                                  name: "documentsIndex",
                                  params: {
                                    parentDirectory: directory.relativePath,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "document-block | cover",
                                  style: {
                                    "background-image":
                                      'url("' + directory.imageUrl + '")',
                                  },
                                },
                                [
                                  _c("div", { staticClass: "title-beam" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(directory.name) +
                                        "\n                                "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoading()
                ? _c("div", { staticClass: "grid grid--center" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.trans("shop.table.loading-records")) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }