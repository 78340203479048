var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      { staticClass: "product-add-bar__message", attrs: { role: "alert" } },
      [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } }),
        _vm._v(" "),
        _vm.removable
          ? _c(
              "button",
              {
                staticClass: "alert-close",
                attrs: { type: "button", "aria-label": "Close" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("remove")
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }