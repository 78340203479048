<template>
    <div class="section section--no-padding | u-margin-bottom">
        <div class="container">
            <div
                v-if="category"
                class="page-block | u-margin-bottom"
            >
                <div class="page-block__header">
                    <div
                        class="page-block__breadcrumbs">
                        <router-link
                            :to="{ name: 'category.index' }"
                            class="page-block__breadcrumb-link"
                        >{{ trans('page.shop.index.breadcrumb') }}</router-link>&nbsp;»&nbsp;{{ category.name }}
                    </div>

                    <category-menu :main-categories="mainCategories" />
                </div>

                <h1 class="alpha | page-block__title">{{ category.name }}</h1>
                <p
                    v-if="category.description"
                    class="page-block-wysiwyg"
                    v-html="category.description"
                />
                <div class="grid">
                    <div
                        v-for="(subCategory, index) in categories"
                        :key="index"
                        class="grid__item grid__item--sub-category"
                    >
                        <router-link
                            :to="{ name: 'category.productDetail', params: { categoryId: category.id, subCategoryId: subCategory.id } }"
                            class="page-block__link"
                        >
                            <div
                                :style="{ 'background-image': 'url(' + subCategory.image + ')' }"
                                class="page-block_subcategory | cover"
                            >
                                <div class="page-block__title-beam">
                                    {{ subCategory.name }}
                                    <span v-if="subCategory.intro">
                                        {{ subCategory.intro }}
                                    </span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CategoryMenu from '../components/category/MenuList.vue';

export default {
    components: {
        CategoryMenu
    },
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        mainCategories: {
            type: Array,
            default: () => []
        },
        category: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        /**
         * Check if there is a background image.
         *
         * @param {object} category
         *
         * @return {object}
         */
        backgroundImage(category) {
            if (!category.image) {
                return;
            }

            return { backgroundImage: 'url(' + category.image + ')' };
        }
    }
};
</script>
