var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout | wizard | u-margin-bottom u-margin-left-flush" },
    [
      _c(
        "div",
        {
          staticClass: "layout__item u-1/3-portable u-1/3-desk | wizard__item",
          class: { "is-active": _vm.step == 1 },
        },
        [
          _c(
            "span",
            { staticClass: "wizard__title" },
            [
              _c("router-link", { attrs: { to: { name: "checkoutCart" } } }, [
                _c("span", { staticClass: "u-display--none-desk" }, [
                  _vm._v("\n                    #1\n                "),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "u-display--none-portable" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.trans("page.checkout.cart.heading")) +
                      "\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "layout__item u-1/3-portable u-1/3-desk | wizard__item",
          class: { "is-active": _vm.step == 2 },
        },
        [
          _c(
            "span",
            { staticClass: "wizard__title" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "checkoutShipping" } } },
                [
                  _c("span", { staticClass: "u-display--none-desk" }, [
                    _vm._v("\n                    #2\n                "),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "u-display--none-portable" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.trans("page.checkout.shipping.heading")) +
                        "\n                "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "layout__item u-1/3-portable u-1/3-desk | wizard__item",
          class: { "is-active": _vm.step == 3 },
        },
        [
          _c("span", { staticClass: "wizard__title" }, [
            _c(
              "button",
              {
                staticClass: "btn btn--link",
                attrs: { disabled: !_vm.acceptedTerms },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "checkoutConfirmation" })
                  },
                },
              },
              [
                _c("span", { staticClass: "u-display--none-desk" }, [
                  _vm._v("\n                #3\n            "),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "u-display--none-portable" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("page.checkout.confirmation.heading")) +
                      "\n            "
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }