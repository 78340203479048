var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section section--no-padding | u-margin-bottom" },
    [
      _c("div", { staticClass: "container" }, [
        _vm.category
          ? _c("div", { staticClass: "page-block | u-margin-bottom" }, [
              _c(
                "div",
                { staticClass: "page-block__header" },
                [
                  _c(
                    "div",
                    { staticClass: "page-block__breadcrumbs" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "page-block__breadcrumb-link",
                          attrs: { to: { name: "category.index" } },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.trans("page.shop.index.breadcrumb"))
                          ),
                        ]
                      ),
                      _vm._v(
                        " » " + _vm._s(_vm.category.name) + "\n                "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("category-menu", {
                    attrs: { "main-categories": _vm.mainCategories },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("h1", { staticClass: "alpha | page-block__title" }, [
                _vm._v(_vm._s(_vm.category.name)),
              ]),
              _vm._v(" "),
              _vm.category.description
                ? _c("p", {
                    staticClass: "page-block-wysiwyg",
                    domProps: { innerHTML: _vm._s(_vm.category.description) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid" },
                _vm._l(_vm.categories, function (subCategory, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "grid__item grid__item--sub-category",
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "page-block__link",
                          attrs: {
                            to: {
                              name: "category.productDetail",
                              params: {
                                categoryId: _vm.category.id,
                                subCategoryId: subCategory.id,
                              },
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "page-block_subcategory | cover",
                              style: {
                                "background-image":
                                  "url(" + subCategory.image + ")",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "page-block__title-beam" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(subCategory.name) +
                                      "\n                                "
                                  ),
                                  subCategory.intro
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(subCategory.intro) +
                                            "\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }