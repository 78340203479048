<template>
    <div class="section section--no-padding | u-padding-top-narrow u-margin-bottom-narrow">
        <div class="container">
            <div class="field-search | u-relative">
                <searchWidget
                    v-model="searchValue"
                    :placeholder="trans('shop.product-search.placeholder')"
                    endpoint="/shop/product-search"
                    query-parameter-name="query"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            searchValue: null
        };
    },
    watch: {
        /**
         * Redirect to the category page after clicking on a search result.
         *
         * @param {string} value
         */
        searchValue(value) {
            document.location = value;
        }
    },
    methods: {}
};
</script>
