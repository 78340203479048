<template>
    <div>
        <table class="table table--responsive table--product">
            <thead>
                <tr>
                    <td>{{ trans('shop.profile.order-table.order-number') }}</td>
                    <td>{{ trans('shop.profile.order-table.status') }}</td>
                    <td>{{ trans('shop.profile.order-table.date') }}</td>
                    <td>{{ trans('shop.profile.order-table.packing-slip') }}</td>
                    <td>{{ trans('shop.profile.order-table.invoice') }}</td>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(order, index) in orders"
                    :key="index"
                    :index="index"
                >
                    <td>
                        <router-link
                            :to="{ name: 'shop.profile.order', params: { orderId: order.id } }"
                            class="link-purple"
                        >{{ order.order_number }}</router-link>
                    </td>
                    <td>{{ order.status }}</td>
                    <td>{{ order.ordered_date }}</td>
                    <td>
                        <a
                            v-if="order.packing_slip_url"
                            :href="order.packing_slip_url"
                            class="link-purple"
                        >
                            {{ trans('shop.profile.order-table.download') }}
                        </a>
                    </td>
                    <td>
                        <a
                            v-if="order.invoice_url"
                            :href="order.invoice_url"
                            class="link-purple"
                        >
                            {{ trans('shop.profile.order-table.download') }}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="loading">{{ trans('shop.table.loading-records') }}</div>
    </div>
</template>
<script>
export default {
    props: {
        orders: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>
