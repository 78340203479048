import BaseCache from './BaseCache.js';

/**
 * Directory cache, handle the sync with the server, and cache the results.
 */
class DirectoryCache extends BaseCache {
    /**
     * Set the variables.
     *
     * @param {object} options
     */
    constructor(options = {}) {
        super({
            name: 'directories_' + options.parentDirectory,
            route: route('shop.documents.directories', { directory: options.parentDirectory }),
            store: 'directories/addDirectories',
        });
    }
}

export default DirectoryCache;
