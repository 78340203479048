var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section section--categories section--purple-light" },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "grid grid--center" },
          _vm._l(_vm.categories, function (category, index) {
            return _c(
              "div",
              { key: index, staticClass: "grid__item grid__item--category" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "u-no-hover-underline",
                    attrs: {
                      to: {
                        name: "category.detail",
                        params: { categoryId: category.id },
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "category-block" }, [
                      category.image
                        ? _c("img", {
                            staticClass:
                              "page-block_category-image | u-margin-bottom-narrow",
                            attrs: { src: category.image },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "beta | u-margin-bottom-none u-margin-top-auto",
                        },
                        [_vm._v(_vm._s(category.name))]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }