var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section section--no-padding | u-margin-bottom" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "page-block page-block--wizard | u-margin-bottom",
              },
              [
                _c("WizardSteps", { attrs: { step: 1 } }),
                _vm._v(" "),
                _c("div", { staticClass: "wizard__step" }, [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "wizard__table-title | u-margin-bottom-narrow",
                    },
                    [_vm._v(_vm._s(_vm.trans("shop.wizard.cart.title")))]
                  ),
                  _vm._v(" "),
                  _c("table", { staticClass: "table table--responsive" }, [
                    _c("thead", [
                      _c("tr", { staticClass: "u-vertical-align-baseline" }, [
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.item-number"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.description"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.unit"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.price-tier"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.price"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.quantity"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.trans("shop.table.total-price"))),
                        ]),
                        _vm._v(" "),
                        _c("td"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.cartItems, function (cartItem, index) {
                        return _c("cart-item-row-partial", {
                          key: index,
                          attrs: { "cart-item": cartItem },
                        })
                      }),
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "u-margin-bottom u-text-right" }, [
                    _c("h3", { staticClass: "wizard__table-title" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.trans("shop.wizard.cart.order-total")) +
                          " "
                      ),
                      _c("span", { staticClass: "wizard__table-total-price" }, [
                        _vm._v(_vm._s(_vm._f("toEuro")(_vm.cartTotalPrice))),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "u-padding" }, [
                  _c("div", { staticClass: "wizard__help-text" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.trans("shop.wizard.live-chat-text")) +
                        "\n                        "
                    ),
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.trans("shop.wizard.live-chat-contact")) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "u-separator-line" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "wizard__steps wizard__steps--cart" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "category.index" } } },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn--ghost btn--continue btn--full-width-responsive",
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.trans(
                                      "shop.wizard.navigation.continue-shopping"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn--primary btn--full-width-responsive",
                          attrs: { disabled: !_vm.isValidQuantity },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "checkoutShipping",
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.trans("shop.wizard.navigation.step-2")
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }