<template>
    <div>
        <ProductSearchPartial />

        <div class="section section--no-padding | u-margin-bottom">
            <div class="container">
                <div class="page-block | u-margin-bottom">
                    <h1 class="alpha | page-block__title">{{ trans('page.profile.index.heading') }}</h1>

                    <div class="tabs">
                        <profile-tabs/>
                        <div class="tabs__content">
                            <profile-information :user="user"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '../../mixins/page.js';
import ProductSearchPartial from '../../components/ProductSearchPartial.vue';
import ProfileInformation from '../../components/profile/ProfileInformation.vue';
import ProfileTabs from '../../components/profile/ProfileTabs.vue';

export default {
    components: {
        ProductSearchPartial,
        ProfileInformation,
        ProfileTabs
    },
    mixins: [pageMixin],
    props: {
        user: {
            type: Object,
            default: () => {}
        }
    },
    metaInfo: {
        title: trans('page.profile.index.title')
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.finishLoading();
        this.scrollToTop();
    }
};
</script>
