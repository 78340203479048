var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "table table--responsive table--product" }, [
      _c("thead", [
        _c("tr", [
          _c("td", [
            _vm._v(_vm._s(_vm.trans("shop.profile.order-table.order-number"))),
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.trans("shop.profile.order-table.status"))),
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.trans("shop.profile.order-table.date"))),
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.trans("shop.profile.order-table.packing-slip"))),
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.trans("shop.profile.order-table.invoice"))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.orders, function (order, index) {
          return _c("tr", { key: index, attrs: { index: index } }, [
            _c(
              "td",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "link-purple",
                    attrs: {
                      to: {
                        name: "shop.profile.order",
                        params: { orderId: order.id },
                      },
                    },
                  },
                  [_vm._v(_vm._s(order.order_number))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(order.status))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(order.ordered_date))]),
            _vm._v(" "),
            _c("td", [
              order.packing_slip_url
                ? _c(
                    "a",
                    {
                      staticClass: "link-purple",
                      attrs: { href: order.packing_slip_url },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.trans("shop.profile.order-table.download")
                          ) +
                          "\n                    "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("td", [
              order.invoice_url
                ? _c(
                    "a",
                    {
                      staticClass: "link-purple",
                      attrs: { href: order.invoice_url },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.trans("shop.profile.order-table.download")
                          ) +
                          "\n                    "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", [_vm._v(_vm._s(_vm.trans("shop.table.loading-records")))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }