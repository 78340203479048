import BaseCache from './BaseCache.js';

/**
 * Order cache, handle the sync with the server, and cache the results.
 */
class HistoryCache extends BaseCache {
    /**
     * Set the variables.
     */
    constructor() {
        super({
            name: 'history',
            route: route('shop.profile.history.json'),
            store: 'history/addProducts',
        });
    }
}

export default HistoryCache;
