/**
 * Product price service.
 */
class ProductPriceService {
    /**
     * Get the price tier by price tiers and quantity.
     *
     * @param {object} priceTiers, quantity
     *
     * @return {object}
     */
    static getItemPriceTier({ priceTiers, quantity }) {
        if (!priceTiers.length) {
            return;
        }

        return priceTiers.find((priceTier) => quantity >= priceTier.min_quantity);
    }
    /**
     * Get the next price tier by price tiers and quantity.
     *
     * @param {object} priceTiers, quantity
     *
     * @return {object}
     */
    static getItemNextPriceTier({ priceTiers, quantity }) {
        if (!priceTiers.length) {
            return;
        }

        const currentPriceTier = priceTiers.findIndex((priceTier) => quantity >= priceTier.min_quantity);

        if (currentPriceTier < 1) {
            return;
        }

        return priceTiers[currentPriceTier - 1];
    }

    /**
     * Get the item price tier range.
     *
     * @param {object} priceTiers, quantity
     *
     * @return {string}
     */
    static getItemPriceTierRange({ priceTiers, quantity }) {
        const currentPriceTier = this.getItemPriceTier({
            priceTiers: priceTiers,
            quantity: quantity,
        });
        const nextPriceTier = this.getItemNextPriceTier({
            priceTiers: priceTiers,
            quantity: quantity,
        });
        let minimumQuantity = 1;

        if (!currentPriceTier && !nextPriceTier) {
            return;
        }

        if (currentPriceTier && currentPriceTier.min_quantity > 0) {
            minimumQuantity = currentPriceTier.min_quantity;
        }

        if (currentPriceTier && nextPriceTier) {
            return minimumQuantity + ' - ' + (nextPriceTier.min_quantity - 1);
        } else if (currentPriceTier) {
            return '>= ' + minimumQuantity;
        }
    }

    /**
     * Get the price by price tiers and quantity.
     *
     * @param {object} priceTiers, quantity
     *
     * @return {number}
     */
    static getItemPrice({ priceTiers, quantity }) {
        if (!priceTiers.length) {
            return 0;
        }

        const priceTier = this.getItemPriceTier({ priceTiers: priceTiers, quantity: quantity });

        if (priceTier) {
            return priceTier.price_amount;
        }

        return priceTiers[priceTiers.length - 1].price_amount;
    }
}

export default ProductPriceService;
