var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section section--no-padding | u-margin-bottom" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "page-block page-block--wizard | u-margin-bottom",
              },
              [
                _c("WizardSteps", { attrs: { step: 2 } }),
                _vm._v(" "),
                _c("div", { staticClass: "wizard__step" }, [
                  _c("div", { staticClass: "layout | u-margin-bottom" }, [
                    _c("div", { staticClass: "layout__item" }, [
                      _c("h3", { staticClass: "u-margin-bottom-narrow" }, [
                        _vm._v(_vm._s(_vm.trans("shop.wizard.shipping.title"))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "layout" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "layout__item u-1/3-desk | u-margin-bottom-narrow",
                          },
                          [
                            _c(
                              "h2",
                              { staticClass: "u-margin-bottom-narrow" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans(
                                      "shop.wizard.shipping.choose-method"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.deliveryMode,
                                    expression: "form.deliveryMode",
                                  },
                                ],
                                attrs: {
                                  id: "psm1",
                                  name: "preferredShippingMethod",
                                  value: "KEYSTONE",
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.form.deliveryMode,
                                    "KEYSTONE"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "deliveryMode",
                                      "KEYSTONE"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "psm1" } }, [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.trans(
                                        "shop.wizard.shipping.method.keystone"
                                      )
                                    ) +
                                    "\n                                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.right",
                                      value: _vm.trans(
                                        "shop.wizard.tooltip.keystone"
                                      ),
                                      expression:
                                        "trans('shop.wizard.tooltip.keystone')",
                                      modifiers: { right: true },
                                    },
                                  ],
                                  staticClass: "wizard__info",
                                  attrs: { href: "#" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "info-icon",
                                      src: "/dist/front/images/info-icon.png",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.deliveryMode,
                                    expression: "form.deliveryMode",
                                  },
                                ],
                                attrs: {
                                  id: "psm2",
                                  name: "preferredShippingMethod",
                                  value: "OTHER",
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.form.deliveryMode,
                                    "OTHER"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "deliveryMode",
                                      "OTHER"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "psm2" } }, [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.trans(
                                        "shop.wizard.shipping.method.own-transport"
                                      )
                                    ) +
                                    "\n                                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.right",
                                      value: _vm.trans(
                                        "shop.wizard.tooltip.own-transport"
                                      ),
                                      expression:
                                        "trans('shop.wizard.tooltip.own-transport')",
                                      modifiers: { right: true },
                                    },
                                  ],
                                  staticClass: "wizard__info",
                                  attrs: { href: "#" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "info-icon",
                                      src: "/dist/front/images/info-icon.png",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.deliveryMode,
                                    expression: "form.deliveryMode",
                                  },
                                ],
                                attrs: {
                                  id: "psm3",
                                  name: "preferredShippingMethod",
                                  value: "PICKUP",
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.form.deliveryMode,
                                    "PICKUP"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "deliveryMode",
                                      "PICKUP"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "psm3" } }, [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.trans(
                                        "shop.wizard.shipping.method.pick-up"
                                      )
                                    ) +
                                    "\n                                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.right",
                                      value: _vm.trans(
                                        "shop.wizard.tooltip.pick-up"
                                      ),
                                      expression:
                                        "trans('shop.wizard.tooltip.pick-up')",
                                      modifiers: { right: true },
                                    },
                                  ],
                                  staticClass: "wizard__info",
                                  attrs: { href: "#" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "info-icon",
                                      src: "/dist/front/images/info-icon.png",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "field__label",
                                attrs: { for: "PurchaseOrderFormNum" },
                              },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(
                                      _vm.trans(
                                        "shop.wizard.shipping.po-number"
                                      )
                                    ) +
                                    "\n                                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.poNumber,
                                  expression: "form.poNumber",
                                },
                              ],
                              staticClass: "field__input",
                              attrs: {
                                id: "PurchaseOrderFormNum",
                                name: "PurchaseOrderFormNum",
                                maxlength: "20",
                                type: "text",
                              },
                              domProps: { value: _vm.form.poNumber },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "poNumber",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "layout__item u-1/3-desk" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.deliveryMode === "OTHER",
                                  expression: "form.deliveryMode === 'OTHER'",
                                },
                              ],
                            },
                            [
                              _c(
                                "h2",
                                { staticClass: "u-margin-bottom-narrow" },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.trans(
                                          "shop.wizard.shipping.carrier.title"
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "field__label",
                                  attrs: { for: "shippingCarrier" },
                                },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.trans(
                                          "shop.wizard.shipping.carrier.name"
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.carrier,
                                    expression: "form.carrier",
                                  },
                                ],
                                staticClass: "field__input",
                                attrs: {
                                  id: "shippingCarrier",
                                  name: "shippingCarrier",
                                  placeholder: "Name",
                                  type: "text",
                                },
                                domProps: { value: _vm.form.carrier },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "carrier",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "field__label",
                                  attrs: { for: "shippingCarrierPhone" },
                                },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.trans(
                                          "shop.wizard.shipping.carrier.phone-number"
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.phone,
                                    expression: "form.phone",
                                  },
                                ],
                                staticClass: "field__input",
                                attrs: {
                                  id: "shippingCarrierPhone",
                                  name: "shippingCarrierPhone",
                                  placeholder: "Phone number",
                                  type: "text",
                                },
                                domProps: { value: _vm.form.phone },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "phone",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "field__label",
                                  attrs: { for: "shippingCarrierEmail" },
                                },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.trans(
                                          "shop.wizard.shipping.carrier.email"
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.email,
                                    expression: "form.email",
                                  },
                                ],
                                staticClass: "field__input",
                                attrs: {
                                  id: "shippingCarrierEmail",
                                  name: "shippingCarrierEmail",
                                  placeholder: "E-mail address",
                                  type: "text",
                                },
                                domProps: { value: _vm.form.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout | u-margin-bottom" },
                    _vm._l(_vm.customer.addresses, function (address, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "layout__item u-1/3-desk" },
                        [
                          _vm.customerAddressTitle(address)
                            ? [
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(_vm.customerAddressTitle(address))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("address", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(address.name)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(address.street_address)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(address.zip_code) +
                                      " " +
                                      _vm._s(address.city)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(address.country) +
                                      "\n                                "
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "u-padding" }, [
                  _c("div", { staticClass: "wizard__help-text" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.trans("shop.wizard.live-chat-text")) +
                        "\n                        "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "wizard__help-text-link",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.trans("shop.wizard.live-chat-contact")) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "u-separator-line" }),
                  _vm._v(" "),
                  _c("p", { staticClass: "u-text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.termsAccepted,
                          expression: "termsAccepted",
                        },
                      ],
                      staticClass: "xstyled",
                      attrs: { id: "terms", name: "terms", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.termsAccepted)
                          ? _vm._i(_vm.termsAccepted, null) > -1
                          : _vm.termsAccepted,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.termsAccepted,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.termsAccepted = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.termsAccepted = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.termsAccepted = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "terms" } }, [
                      _c("span", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.trans("shop.wizard.terms-text")) +
                            "\n                                "
                        ),
                        _vm.termsAndConditions
                          ? _c(
                              "a",
                              {
                                staticClass: "wizard__link",
                                attrs: {
                                  href: _vm.termsAndConditions,
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.trans("shop.wizard.terms-link-text")
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(_vm.trans("shop.wizard.terms-link-text"))
                              ),
                            ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "wizard__steps" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "checkoutCart" } } },
                        [
                          _c("button", { staticClass: "btn btn--primary" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.trans("shop.wizard.navigation.step-1")
                                ) +
                                "\n                            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn--primary",
                          attrs: {
                            disabled:
                              !_vm.acceptedTerms || !_vm.form.deliveryMode,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "checkoutConfirmation",
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.trans("shop.wizard.navigation.step-3")
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }