/**
 * Format a number to a Dutch formatted currency string (e.g. € 100,00)
 *
 * @param {number} value
 *
 * @return {string}
 */
export function toEuro(value) {
    if (isNaN(value)) {
        return;
    }

    let formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
    });

    return formatter.format(value / 100);
}

/**
 * Format a number to a float for the currency input max
 *
 * @param {number} value
 *
 * @return {string}
 */
export function toDecimal(value) {
    if (isNaN(value)) {
        return;
    }

    return value / 100;
}
