var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c(
        "a",
        {
          staticClass: "wizard__link",
          attrs: { href: _vm.cartItem.product.category_page_url },
        },
        [_vm._v(_vm._s(_vm.cartItem.productNumber))]
      ),
    ]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.cartItem.name))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.cartItem.unit))]),
    _vm._v(" "),
    _vm.editable ? _c("td", [_vm._v(_vm._s(_vm.priceTier))]) : _vm._e(),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm._f("toEuro")(_vm.cartItem.price)))]),
    _vm._v(" "),
    _vm.editable
      ? _c("td", { class: { "has-error": !_vm.quantityIsValid } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.quantity,
                expression: "quantity",
              },
            ],
            staticClass: "field__input field__input--smaller",
            attrs: {
              step: _vm.cartItem.product.quantity_multiplier,
              name: "quantity",
              type: "number",
              min: "0",
              max: "99999",
            },
            domProps: { value: _vm.quantity },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.quantity = $event.target.value
                },
                _vm.updateQuantity,
              ],
            },
          }),
        ])
      : _c("td", [_vm._v(_vm._s(_vm.cartItem.quantity))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm._f("toEuro")(_vm.cartItem.totalPrice)))]),
    _vm._v(" "),
    _vm.editable
      ? _c("td", [
          _c(
            "a",
            {
              staticClass: "wizard__link | u-cursor-pointer",
              attrs: { dusk: "remove-products-button" },
              on: {
                click: function ($event) {
                  return _vm.removeItem(_vm.cartItem)
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.trans("action.delete")) +
                  "\n        "
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }