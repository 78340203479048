<template>
    <div>
        <ProductSearchPartial />

        <div class="section">
            <div class="container">
                <div class="page-block | u-margin-bottom">
                    <div
                        class="u-margin-bottom">
                        <router-link
                            :to="{ name: 'category.index' }"
                            class="page-block__breadcrumb-link"
                        >
                            {{ trans('page.shop.index.breadcrumb') }}
                        </router-link>
                        &nbsp;»&nbsp;
                        {{ trans('page.documents.title') }}
                    </div>

                    <h1 class="alpha | page-block__title">{{ trans('shop.documents.title') }}</h1>

                    <directory-tabs :directories="mainDirectories"/>

                    <div
                        v-if="!isLoading()"
                        class="grid"
                    >
                        <div
                            v-for="(directory, index) in directories"
                            :key="index"
                            class="grid__item grid__item--document"
                        >
                            <router-link
                                :to="{ name: 'documentsIndex', params: { parentDirectory: directory.relativePath } }"
                                class="link-purple | u-no-hover-underline"
                            >
                                <div
                                    :style="{ 'background-image': 'url(&quot;' + directory.imageUrl + '&quot;)' }"
                                    class="document-block | cover"
                                >
                                    <div class="title-beam">
                                        {{ directory.name }}
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div
                        v-if="isLoading()"
                        class="grid grid--center"
                    >
                        {{ trans('shop.table.loading-records') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pageMixin from '../../mixins/page.js';

import ProductSearchPartial from '../../components/ProductSearchPartial.vue';
import DirectoryCache from '../../cache/DirectoryCache.js';
import DirectoryTabs from '../../components/documents/DirectoryTabs.vue';

export default {
    components: {
        ProductSearchPartial,
        DirectoryTabs
    },
    mixins: [pageMixin],

    /**
     * Event callback to detect route change.
     *
     * @param {object}   to
     * @param {object}   from
     * @param {Function} next
     */
    beforeRouteUpdate(to, from, next) {
        this.startLoading();
        this.directoryCache.stopRefresh();
        this.directoryCache = new DirectoryCache({
            parentDirectory: this.getDirectory(to.params.parentDirectory).name
        });
        this.loadDirectories();
        next();
    },
    props: {
        mainDirectories: {
            type: Array,
            default: () => []
        }
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            directoryCache: null
        };
    },

    computed: {
        ...mapGetters({
            getDirectories: 'directories/directories'
        }),

        /**
         * Gets the parent directory.
         *
         * @return {object}
         */
        parentDirectory() {
            return this.getDirectory(this.defaultParentDirectory);
        },

        /**
         * Get the directories of the current parent directory.
         *
         * @return {array}
         */
        directories() {
            return this.getDirectories(this.parentDirectory.relativePath);
        },

        /**
         * Gets the default parent directory.
         *
         * @return {object}
         */
        defaultParentDirectory() {
            return this.$route.params.parentDirectory;
        }
    },

    metaInfo: {
        title: trans('page.documents.title')
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.scrollToTop();
        const parentDirectory = this.parentDirectory;
        let name = '';
        
        if (parentDirectory) {
            name = parentDirectory.name;
        }
        this.directoryCache = new DirectoryCache({ parentDirectory: name });
        this.loadDirectories();
    },
    methods: {
        /**
         * Loads directories into the store via an ajax request.
         *
         * @return {Promise}
         */
        loadDirectories() {
            return this.directoryCache
                .getAll()
                .then(this.finishLoading)
                .catch(error => {
                    console.error(error);
                    console.trace();
                });
        },

        /**
         * Get the directory by name, or return the first category.
         *
         * @param {string} directoryName
         *
         * @return {string}
         */
        getDirectory(directoryName) {
            if (directoryName) {
                return this.mainDirectories.find(directory => directory.name === directoryName);
            }

            return this.mainDirectories[0];
        }
    }
};
</script>
