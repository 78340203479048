<template>
    <div>
        <router-link
            :to="{ name: 'shop.profile.products' }"
            class="tabs__label"
        >{{ trans('shop.profile.tabs.product-history') }}</router-link>
        <router-link
            :to="{ name: 'shop.profile.orders' }"
            class="tabs__label"
        >{{ trans('shop.profile.tabs.order-history') }}</router-link>
        <router-link
            :to="{ name: 'shop.profile' }"
            class="tabs__label"
        >{{ trans('shop.profile.tabs.information') }}</router-link>
    </div>
</template>
<script>
export default {};
</script>
