var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "directory-tabs" },
    _vm._l(_vm.directories, function (directory, index) {
      return _c(
        "router-link",
        {
          key: index,
          staticClass: "tabs__label",
          attrs: {
            to: {
              name: "directoriesIndex",
              params: _vm.getParameters(index, directory),
            },
          },
        },
        [_vm._v(_vm._s(directory.name))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }