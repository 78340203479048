var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field", class: _vm.stateClasses },
    [
      _c(
        "v-select",
        {
          staticClass: "field__input-search",
          attrs: {
            placeholder: _vm.placeholder,
            options: _vm.options,
            filterable: false,
          },
          on: { input: _vm.onChange, search: _vm.onSearch },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function (option) {
                return [
                  _c("div", { staticClass: "d-center" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(option.label) +
                        "\n            "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "selected-option",
              fn: function (option) {
                return [
                  _c("div", { staticClass: "selected d-center" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(option.label) +
                        "\n            "
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _vm.error
            ? [
                _c("span", { staticClass: "field__message" }, [
                  _vm._v(_vm._s(_vm.error)),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("template", { slot: "no-options" }, [
            _vm.searchKeyword
              ? _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("shop.product-search.no-records")) +
                      "\n            "
                  ),
                ])
              : _c("span", [_vm._v("\n                 \n            ")]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }