<template>
    <div class="category-menu | clearfix">
        <menu-item
            v-for="(category, index) in mainCategories"
            :key="index"
            :category="category"
        />
    </div>
</template>

<script>
import MenuItem from './MenuItem.vue';
export default {
    components: {
        MenuItem
    },
    props: {
        mainCategories: {
            type: Array,
            default: () => []
        }
    }
};
</script>
