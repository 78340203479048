import ProductPriceService from '../../services/productPrice.js';

const state = {
    shipping: {
        deliveryMode: null,
        carrier: null,
        phone: null,
        email: null,
        poNumber: null,
    },
    items: [],
    acceptedTerms: false,
    notes: null,
    validQuantity: true,
};

const getters = {
    shipping: (state) => state.shipping,
    acceptedTerms: (state) => state.acceptedTerms,
    notes: (state) => state.notes,
    items: (state) => state.items,
    isValidQuantity: (state) => state.validQuantity,

    totalPrice: (state) => {
        if (!state.items) {
            return;
        }

        return state.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0);
    },

    totalItems: (state) => {
        if (!state.items) {
            return;
        }

        return state.items.reduce((accumulator, item) => accumulator + item.quantity, 0);
    },
};

const actions = {
    /**
     * Add item to cart.
     *
     * @param {object} commit, state
     * @param {object} newItem
     */
    addItem({ commit, state }, newItem) {
        if (!newItem.product || typeof newItem.product != 'object') {
            throw { message: 'Action | Add cart item: item doesnt contain a product object', item: newItem };
        }

        const existingItem = state.items.find((item) => item.product.id == newItem.product.id);

        try {
            if (existingItem) {
                newItem.quantity += existingItem.quantity;
                commit('updateItemQuantity', newItem);
            } else {
                commit('addItem', newItem);
            }
            commit('calculatePrice', newItem);
        } catch (error) {
            console.error(error);
            console.trace();
        }
    },

    /**
     * Remove an item from the cart.
     *
     * @param {object} commit
     * @param {object} item
     */
    removeItem({ commit }, item) {
        try {
            commit('removeItem', item);
        } catch (error) {
            console.error(error);
            console.trace();
        }
    },

    /**
     * Update the quantity of the item in the cart.
     *
     * @param {object} commit
     * @param {object} item, quantity
     */
    updateItemQuantity({ commit }, { item, quantity }) {
        try {
            item.quantity = quantity;
            commit('updateItemQuantity', item);
            commit('calculatePrice', item);
        } catch (error) {
            console.error(error);
            console.trace();
        }
    },

    /**
     * Set the shipping information.
     *
     * @param {object} commit
     * @param {object} deliveryMode, carrier, phone, email, poNumber
     */
    setShipping({ commit }, { deliveryMode, carrier, phone, email, poNumber }) {
        let data = {
            deliveryMode: deliveryMode,
            carrier: carrier,
            phone: phone,
            email: email,
            poNumber: poNumber,
        };

        commit('setShipping', data);
    },

    /**
     * Set the shipping po number.
     *
     * @param {object} commit, state
     * @param {string} poNumber
     */
    setPONumber({ commit, state }, poNumber) {
        let shipping = state.shipping;

        shipping.poNumber = poNumber;

        commit('setShipping', shipping);
    },

    /**
     * Update the terms condition.
     *
     * @param {object} commit
     * @param {boolean} value
     */
    acceptTerms({ commit }, value) {
        if (typeof value == 'boolean') {
            commit('acceptTerms', value);
        }
    },

    /**
     * Update the notes.
     *
     * @param {object} commit
     * @param {string} value
     */
    setNotes({ commit }, value) {
        commit('setNotes', value);
    },

    /**
     * Set the intial cart data from inline JSON.
     *
     * @param {object} commit
     * @param {object} initialCart
     */
    setInitialData({ commit }, initialCart) {
        if (!initialCart) {
            return;
        }

        try {
            commit('setCart', initialCart);
        } catch (error) {
            console.error(error);
            console.trace();
        }
    },

    /**
     * Reset the cart.
     *
     * @param {object} commit
     */
    reset({ commit }) {
        commit('reset');
    },

    /**
     * Update the valid quantity state.
     *
     * @param {object} commit
     * @param {boolean} state
     */
    updateValidQuantity({ commit }, state) {
        commit('updateValidQuantity', state);
    },
};

const mutations = {
    /**
     * Add item to the cart state.
     *
     * @param {object} state
     * @param {object} item
     */
    addItem(state, item) {
        if (item.quantity <= 0 || typeof item.quantity != 'number') {
            throw { message: 'Mutation | Add item: quantity is not a valid number', quantity: item.quantity };
        }

        state.items.push(item);
    },

    /**
     * Update the quantity of a item.
     *
     * @param {object} state
     * @param {object} newItem
     */
    updateItemQuantity(state, newItem) {
        if (!newItem.product || typeof newItem.product != 'object') {
            throw { message: 'Mutation | Update cart item: item doesnt contain a product object', item: newItem };
        }

        let existingItem = state.items.find((item) => item.product.id == newItem.product.id);

        if (newItem.quantity <= 0 || typeof newItem.quantity != 'number') {
            throw {
                message: 'Mutation | Update cart item: quantity is not a valid number',
                quantity: newItem.quantity,
            };
        }

        existingItem.quantity = newItem.quantity;
    },

    /**
     * Remove a item from the cart.
     *
     * @param {object} state
     * @param {object} newItem
     */
    removeItem(state, newItem) {
        if (!newItem.product || typeof newItem.product != 'object') {
            throw { message: 'Mutation | Update cart item: item doesnt contain a product object', item: newItem };
        }

        const cartItem = state.items.find((item) => item.product.id == newItem.product.id);

        if (!cartItem) {
            throw { message: 'Mutation | Remove cart item: cannot find item', item: newItem };
        }

        state.items = state.items.filter((item) => item.product.id != newItem.product.id);
    },

    /**
     * Update the item price and item total price.
     *
     * @param {object} state
     * @param {object} item
     */
    calculatePrice(state, item) {
        if (!item.product || typeof item.product != 'object') {
            throw { message: 'Mutation | calculate item price: item doesnt contain a product object', item: item };
        }

        item.price = ProductPriceService.getItemPrice({ priceTiers: item.product.price, quantity: item.quantity });
        item.totalPrice = item.quantity * item.price;
    },

    /**
     * Update the shipping information.
     *
     * @param {object} state
     * @param {object} deliveryMode, carrier, phone, email, poNumber
     */
    setShipping(state, { deliveryMode, carrier, phone, email, poNumber }) {
        state.shipping = {
            deliveryMode: deliveryMode,
            carrier: carrier,
            phone: phone,
            email: email,
            poNumber: poNumber,
        };
    },

    /**
     * Update the terms condition.
     *
     * @param {object} state
     * @param {boolean} value
     */
    acceptTerms(state, value) {
        state.acceptedTerms = value;
    },

    /**
     * Update the notes.
     *
     * @param {object} state
     * @param {string} value
     */
    setNotes(state, value) {
        state.notes = value;
    },

    /**
     * Replace the current cart object with a new object.
     *
     * @param {object} state
     * @param {object} cart
     */
    setCart(state, cart) {
        if (cart.shipping) {
            state.shipping = cart.shipping;
        }

        if (cart.acceptedTerms) {
            state.acceptedTerms = cart.acceptedTerms;
        }

        if (cart.notes) {
            state.notes = cart.notes;
        }

        if (cart.items) {
            state.items = cart.items.map((item) => {
                if (item.quantity <= 0 || typeof item.quantity != 'number') {
                    throw { message: 'Mutation | Set cart: quantity is not a valid number', quantity: item.quantity };
                }

                item.total = item.quantity * item.price;

                return item;
            });
        }
    },

    /**
     * Reset the cart.
     *
     * @param {object} state
     */
    reset(state) {
        state.shipping = {
            deliveryMode: null,
            carrier: null,
            phone: null,
            email: null,
            poNumber: null,
        };
        state.items = [];
        state.acceptedTerms = false;
        state.notes = null;
    },

    /**
     * Update the valid quantity state.
     *
     * @param {object} state
     * @param {boolean} value
     */
    updateValidQuantity(state, value) {
        state.validQuantity = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
