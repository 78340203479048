import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import Way2Forms from './plugins/way2forms';
import vSelect from 'vue-select';

import { setLocale } from './helpers/date.js';
import { toEuro, toDecimal } from './helpers/currency.js';
import { getDateFromW3c } from './helpers/date.js';

import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import VTooltip from 'v-tooltip';

import './polyfills/array-find.js';
import './polyfills/array-findindex.js';
import './polyfills/array-includes.js';
import './polyfills/object-assign.js';
import './polyfills/string-includes.js';

/**
 * Intitialize the moment locale.
 */
setLocale('nl');

/**
 * A wrapper around the trans() function, so we can be constant between the front-end and the back-end code.
 *
 * @param  {string} string
 * @param  {array} args
 *
 * @return {string}
 */
window.trans = function (string, args) {
    return Lang.get(string, args);
};

/**
 * A wrapper around the Lang.choice() function, so we can be consistent between the front-end and the back-end code.
 *
 * @param  {string} string
 * @param  {number} amount
 * @param  {object} args
 *
 * @return {string}
 */
window.trans_choice = function (string, amount, args) {
    return Lang.choice(string, amount, args);
};

/**
 * Bind the global helper functions to Vue for use inside the template parts.
 */
/* eslint-disable camelcase */
Vue.prototype.trans = trans;
Vue.prototype.trans_choice = trans_choice;
Vue.prototype.route = route;
/* eslint-enable camelcase */

/**
 * Plugins
 */
Vue.use(VueScrollTo);
Vue.use(Way2Forms);
Vue.use(VTooltip);

Vue.component('VSelect', vSelect);

/**
 * Custom Vue filters
 */
Vue.filter('toEuro', toEuro);
Vue.filter('toDecimal', toDecimal);
Vue.filter('date', getDateFromW3c);

/**
 * Set the CSRF token for Laravel.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found');
}

/**
 * Check if the .env file has a Sentry public dsn key and if the current environment is live.
 * When both are true Initialize Raven
 */
/* eslint-disable */
if (process.env.SENTRY_DSN_PUBLIC && process.env.APP_ENV == 'live') {
    Raven.config(process.env.SENTRY_DSN_PUBLIC, {
        environment: 'live'
    })
        .addPlugin(RavenVue, Vue)
        .install();
}
/* eslint-enable */
