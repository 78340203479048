<template>
    <div>
        <ProductSearchPartial />

        <div class="section section--no-padding | u-margin-bottom">
            <div class="container">
                <div class="page-block | u-margin-bottom">
                    <h1 class="alpha | page-block__title">{{ trans('shop.new-developments.title') }}</h1>

                    <div class="grid">
                        <div
                            v-for="newDevelopment in newDevelopments"
                            :key="newDevelopment.order"
                            class="grid__item grid__item--new-developments | u-relative">
                            <div
                                :style="'background-image: url(' + newDevelopment.thumbnail + ');'"
                                class="new-developments__content | cover"
                            >
                                <a
                                    :href="newDevelopment.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div class="new-developments__overlay">
                                        {{ trans('shop.new-developments.download') }}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '../mixins/page.js';

import ProductSearchPartial from '../components/ProductSearchPartial.vue';

export default {
    components: {
        ProductSearchPartial
    },
    mixins: [pageMixin],
    props: {
        newDevelopments: {
            type: Array,
            default: () => []
        }
    },
    metaInfo: {
        title: trans('page.new-developments.title')
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.finishLoading();
        this.scrollToTop();
    }
};
</script>
