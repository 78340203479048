<template>
    <div class="directory-tabs">
        <router-link
            v-for="(directory, index) in directories"
            :key="index"
            :to="{ name: 'directoriesIndex', params: getParameters(index, directory) }"
            class="tabs__label"
        >{{ directory.name }}</router-link>
    </div>
</template>
<script>
export default {
    props: {
        directories: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        /**
         * Gets the proper parameters depending on the index of the directory in the list.
         * The reason is that the first tab needs to be without parameters to enable default
         * selection of that tab
         * @param {integer} index
         * @param {object} directory
         *
         * @return {object}
         */
        getParameters(index, directory) {
            if (0 == index) {
                return {};
            }

            return { parentDirectory: directory.name };
        }
    }
};
</script>
