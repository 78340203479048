const state = {
    products: [],
};

const getters = {
    product: (state) => (productId) => state.products.find((product) => product.id == productId),
    products: (state) => state.products.filter((product) => product.price.length),
};

const actions = {
    /**
     * Add all products.
     *
     * @param {object} commit
     * @param {array} products
     */
    addProducts({ commit }, products) {
        commit('setItems', products);
    },
};

const mutations = {
    /**
     * Add all products.
     *
     * @param {object} state
     * @param {array} products
     */
    setItems(state, products) {
        state.products = products;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
