<template>
    <div>
        <ProductSearchPartial />

        <CategoryPartial
            :categories="categories"
        />

        <NewProductPartial
            :new-product-content="newProductContent"
            :new-product-image="newProductImage"
        />
    </div>
</template>

<script>
import ApiMixin from '../mixins/api.js';
import pageMixin from '../mixins/page.js';

import CategoryPartial from '../components/CategoryPartial.vue';
import NewProductPartial from '../components/NewProductPartial.vue';
import ProductSearchPartial from '../components/ProductSearchPartial.vue';

export default {
    components: {
        NewProductPartial,
        CategoryPartial,
        ProductSearchPartial
    },
    mixins: [pageMixin, ApiMixin],
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        newProductContent: {
            type: String,
            default: ''
        },
        newProductImage: {
            type: String,
            default: ''
        }
    },
    metaInfo: {
        title: trans('page.shop.index.title')
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.finishLoading();
        this.scrollToTop();
    }
};
</script>
