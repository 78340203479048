var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "router-link",
        {
          staticClass: "tabs__label",
          attrs: { to: { name: "shop.profile.products" } },
        },
        [_vm._v(_vm._s(_vm.trans("shop.profile.tabs.product-history")))]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "tabs__label",
          attrs: { to: { name: "shop.profile.orders" } },
        },
        [_vm._v(_vm._s(_vm.trans("shop.profile.tabs.order-history")))]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "tabs__label", attrs: { to: { name: "shop.profile" } } },
        [_vm._v(_vm._s(_vm.trans("shop.profile.tabs.information")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }