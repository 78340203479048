<template>
    <div
        :class="stateClasses"
        class="field"
    >
        <v-select
            :placeholder="placeholder"
            :options="options"
            :filterable="false"
            class="field__input-search"
            @input="onChange"
            @search="onSearch"
        >
            <template v-if="error">
                <span class="field__message">{{ error }}</span>
            </template>
            <template slot="no-options">
                <span v-if="searchKeyword">
                    {{ trans('shop.product-search.no-records') }}
                </span>
                <span v-else>
                    &nbsp;
                </span>
            </template>
            <template
                slot="option"
                slot-scope="option">
                <div class="d-center">
                    {{ option.label }}
                </div>
            </template>
            <template
                slot="selected-option"
                slot-scope="option">
                <div class="selected d-center">
                    {{ option.label }}
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
import axios from 'axios';

/* global require */
const _ = require('lodash');

export default {
    name: 'SearchWidget',
    props: {
        placeholder: {
            type: String,
            default: null
        },
        value: {
            type: [Number, String],
            default: null
        },
        error: {
            type: String,
            default: null
        },
        endpoint: {
            type: String,
            default: null
        },
        queryParameterName: {
            type: String,
            default: null
        }
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            searchKeyword: null,
            newValue: this.value,
            options: []
        };
    },
    computed: {
        /**
         * Adds a class if an error has occurred.
         *
         * @return {object}
         */
        stateClasses() {
            return {
                'has-error': this.error
            };
        }
    },
    watch: {
        /**
         * When v-model is changed:
         *   1. Set internal value.
         *   2. If it's invalid, validate again.
         *
         * @param {string} value
         */
        value(value) {
            this.newValue = value;
        },

        /**
         * Update the v-model and validate again whenever
         * internal value is changed.
         *
         * @param {string} value
         */
        newValue(value) {
            this.$emit('input', value);
        }
    },
    methods: {
        /**
         * search event callback.
         *
         * @param {string} search
         * @param {boolean} loading
         */
        onSearch(search, loading) {
            this.options = [];
            this.searchKeyword = search;

            loading(true);
            this.search(loading, search, this);
        },

        /**
         * Get the search results from the endpoint.
         *
         * @param {Function} loading
         * @param {string} value
         * @param {object} component
         */
        search: _.debounce((loading, search, component) => {
            component.options = [];
            axios
                .get(component.endpoint + '?' + component.queryParameterName + '=' + escape(search.trim()))
                .then(response => {
                    component.options = response.data.data.map(item => {
                        return {
                            value: item.value,
                            label: item.label
                        };
                    });
                })
                .then(() => {
                    loading(false);
                });
        }, 350),

        /**
         * Input event callback.
         *
         * @param {string} newOption
         */
        onChange(newOption) {
            if (!newOption) return;

            this.$nextTick(() => {
                this.newValue = newOption.value;
            });
        }
    }
};
</script>
