var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c("CategoryPartial", { attrs: { categories: _vm.categories } }),
      _vm._v(" "),
      _c("NewProductPartial", {
        attrs: {
          "new-product-content": _vm.newProductContent,
          "new-product-image": _vm.newProductImage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }