import axios from 'axios';
import store from '../store/index.js';

/**
 * Base cache, handle the sync with the server, and cache the results.
 */
class BaseCache {
    /**
     * Set the variables.
     *
     * @param {object} options
     */
    constructor(options = {}) {
        this.cache = options.cache || [];
        this.route = options.route || null;
        this.name = options.name || 'base';
        this.store = options.store || null;
        this.interval = options.interval || 0;
        this.processing = false;
        this.intervalId = null;
    }

    /**
     * Get the storage name.
     *
     * @return {string}
     */
    get storageName() {
        return 'cache_' + this.name;
    }

    /**
     * Get and cache the orders from the server.
     *
     * @return {Promise}
     */
    getAll() {
        let component = this;

        if (!this.cache.length) {
            if (this.name && sessionStorage.getItem(component.storageName)) {
                this.cache = JSON.parse(sessionStorage.getItem(component.storageName));
                if (this.store) {
                    store.dispatch(this.store, this.cache);
                }
            } else {
                this.cache = [];
            }
        }

        if (this.cache.length) {
            return new Promise(this.checkCache.bind(component));
        }

        return this.renew();
    }

    /**
     * Get a single item.
     *
     * @param {object} key, value
     *
     * @return {Promise}
     */
    getSingle({ key, value }) {
        return this.getAll().then((items) => {
            const singleItem = items.find((item) => item[key] == value);

            return new Promise((resolve, reject) => {
                if (singleItem) {
                    resolve(singleItem);
                } else {
                    reject(new Error('Item not found (' + key + ':' + value + ')'));
                }
            });
        });
    }

    /**
     * Wait for the items in the cache.
     *
     * @param {function} resolve
     * @param {function} reject
     */
    checkCache(resolve, reject) {
        let component = this;

        if (component.cache.length < 1) {
            reject(new Error('Cache is empty'));
        }

        let check = () => {
            if (component.cache && component.cache.length) {
                resolve(component.cache);
            }
            setTimeout(check, 100);
        };

        check();

        component.renew();
        this.refreshCache();
    }

    /**
     * Renew the cache every x seconds.
     */
    refreshCache() {
        let component = this;

        if (component.interval <= 0 || component.intervalId) {
            return;
        }

        component.intervalId = setInterval(() => {
            if (!component.processing) {
                component.renew();
            }
        }, component.interval);
    }

    /**
     * Stop the cache refresh.
     */
    stopRefresh() {
        clearInterval(this.intervalId);
    }

    /**
     * Get the information, store in the cache.
     *
     * @return {Promise}
     */
    renew() {
        let component = this;

        if (!this.route) {
            return;
        }

        component.processing = true;

        return axios
            .get(this.route)
            .then((response) => {
                component.processing = false;

                if (!response.data.data) {
                    return;
                }

                if (component.name) {
                    sessionStorage.setItem(component.storageName, JSON.stringify(response.data.data));
                }

                if (component.store) {
                    store.dispatch(component.store, response.data.data);
                }

                component.refreshCache();

                return (component.cache = response.data.data);
            })
            .catch((error) => Promise.reject(error));
    }
}

export default BaseCache;
