var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProductSearchPartial"),
      _vm._v(" "),
      _c("div", { staticClass: "section" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "page-block | u-margin-bottom" }, [
            _c(
              "div",
              { staticClass: "u-margin-bottom" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "page-block__breadcrumb-link",
                    attrs: { to: { name: "category.index" } },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.trans("page.shop.index.breadcrumb")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v("\n                     » \n                    "),
                _c(
                  "router-link",
                  {
                    staticClass: "page-block__breadcrumb-link",
                    attrs: {
                      to: {
                        name: "directoriesIndex",
                        params: _vm.documentBreadcrumbParameters,
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.trans("page.documents.title")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(
                  "\n                     » \n                    " +
                    _vm._s(_vm.parentDirectoryName) +
                    "\n                "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("h1", { staticClass: "alpha | page-block__title" }, [
              _vm._v(_vm._s(_vm.parentDirectoryName)),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter,
                  expression: "filter",
                },
              ],
              staticClass: "field__input field__input--filter",
              attrs: {
                placeholder: _vm.trans(
                  "shop.documents.table.filter-placeholder"
                ),
                name: "filterDocument",
                type: "text",
              },
              domProps: { value: _vm.filter },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.filter = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("h2", { staticClass: "beta" }, [
              _vm._v(_vm._s(_vm.trans("shop.documents.table.title"))),
            ]),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "table table--responsive table--documents" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.trans("shop.documents.table.name"))),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.trans("shop.documents.table.download"))
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.filteredDocuments, function (documentItem, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(documentItem.filename))]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "a",
                          {
                            staticClass: "link-purple",
                            attrs: {
                              href: documentItem.url,
                              download: documentItem.filename,
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.trans("shop.documents.table.download")
                                ) +
                                "\n                                "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isLoading()
              ? _c("div", [
                  _vm._v(_vm._s(_vm.trans("shop.table.loading-records"))),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }