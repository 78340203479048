var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("product-search-partial"),
      _vm._v(" "),
      _vm.category
        ? _c("sub-category-partial", {
            attrs: {
              categories: _vm.category.subCategories,
              "main-categories": _vm.categories,
              category: _vm.category,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }