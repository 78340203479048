<template>
    <div>
        <ProductSearchPartial />

        <div class="section">
            <div class="container">
                <div class="page-block | u-margin-bottom">
                    <div class="page-block__header">
                        <div
                            class="page-block__breadcrumbs">
                            <router-link
                                :to="{ name: 'category.index' }"
                                class="page-block__breadcrumb-link"
                            >{{ trans('page.shop.index.breadcrumb') }}</router-link>&nbsp;»&nbsp;<router-link
                                :to="{ name: 'category.detail', params: { categoryId: category.id } }"
                                class="page-block__breadcrumb-link"
                            >{{ category.name }}</router-link>&nbsp;»&nbsp;{{ subCategory.name }}
                        </div>

                        <category-menu :main-categories="categories" />
                    </div>

                    <div class="page-block__sub-header">
                        <div
                            :style="{ 'background-image': 'url(' + subCategory.image + ')' }"
                            class="page-block_subcategory-image-small | cover" />

                        <h1 class="alpha | page-block__title | u-margin-bottom-none">{{ subCategory.name }}</h1>
                    </div>

                    <p
                        v-if="subCategory.description"
                        v-html="subCategory.description"
                    />

                    <product-list
                        :title="trans('page.shop.products.title')"
                        :products="products"
                        :product-id="productId"
                        :loading="isLoading()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ApiMixin from '../mixins/api.js';
import pageMixin from '../mixins/page.js';
import ProductSearchPartial from '../components/ProductSearchPartial.vue';
import ProductList from '../components/product/ProductList.vue';
import CategoryMenu from '../components/category/MenuList.vue';
import ProductCache from '../cache/ProductCache.js';

export default {
    components: {
        ProductSearchPartial,
        ProductList,
        CategoryMenu
    },
    mixins: [pageMixin, ApiMixin],
    /**
     * Stop the cache refresh.
     *
     * @param {object}   to
     * @param {object}   from
     * @param {Function} next
     */
    beforeRouteLeave(to, from, next) {
        this.productCache.stopRefresh();
        next();
    },
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        categoryId: {
            type: Number,
            default: null
        },
        subCategoryId: {
            type: Number,
            default: null
        },
        productId: {
            type: String,
            default: null
        }
    },
    /**
     * Add all the properties found in the data object to Vue’s reactivity system.
     *
     * @return {object}
     */
    data() {
        return {
            productCache: null
        };
    },
    metaInfo: {
        title: trans('page.shop.products.title')
    },
    computed: {
        ...mapGetters({
            getProducts: 'products/products'
        }),

        /**
         * Get the products of the current category.
         *
         * @return {array}
         */
        products() {
            return this.getProducts(this.subCategoryId);
        },

        /**
         * Get the category id.
         *
         * @return {number}
         */
        category() {
            return this.categories.find(category => category.id == this.categoryId);
        },

        /**
         * Get the category id.
         *
         * @return {number}
         */
        subCategory() {
            return this.categories
                .find(category => category.id == this.categoryId)
                .subCategories.find(category => category.id == this.subCategoryId);
        }
    },

    /**
     * Runs code after an instance is mounted.
     */
    mounted() {
        this.scrollToTop();

        if (!this.productCache) {
            this.productCache = new ProductCache({
                categoryId: this.category.id,
                subCategoryId: this.subCategory.id
            });
        }

        this.productCache
            .getAll()
            .then(this.finishLoading)
            .catch(error => {
                console.error(error);
                console.trace();
            });
    }
};
</script>
