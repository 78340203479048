var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.newProductContent && _vm.newProductImage
    ? _c(
        "div",
        {
          staticClass:
            "section section--filled section--white section--promoted | cover",
          style: _vm.backgroundImage,
        },
        [
          _vm.newProductContent
            ? _c("div", {
                staticClass: "container",
                domProps: { innerHTML: _vm._s(_vm.newProductContent) },
              })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }