<template>
    <div>
        <ProductSearchPartial />

        <div class="section section--no-padding | u-margin-bottom">
            <div class="container">
                <div class="page-block page-block--wizard | u-margin-bottom">
                    <WizardSteps :step="1" />

                    <div class="wizard__step">
                        <h3 class="wizard__table-title | u-margin-bottom-narrow">{{ trans('shop.wizard.cart.title') }}</h3>

                        <table class="table table--responsive">
                            <thead>
                                <tr class="u-vertical-align-baseline">
                                    <td>{{ trans('shop.table.item-number') }}</td>
                                    <td>{{ trans('shop.table.description') }}</td>
                                    <td>{{ trans('shop.table.unit') }}</td>
                                    <td>{{ trans('shop.table.price-tier') }}</td>
                                    <td>{{ trans('shop.table.price') }}</td>
                                    <td>{{ trans('shop.table.quantity') }}</td>
                                    <td>{{ trans('shop.table.total-price') }}</td>
                                    <td/>
                                </tr>
                            </thead>
                            <tbody>
                                <cart-item-row-partial
                                    v-for="(cartItem, index) in cartItems"
                                    :key="index"
                                    :cart-item="cartItem"
                                />
                            </tbody>
                        </table>
                        <div class="u-margin-bottom u-text-right">
                            <h3 class="wizard__table-title">
                                {{ trans('shop.wizard.cart.order-total') }} <span class="wizard__table-total-price">{{ cartTotalPrice | toEuro }}</span>
                            </h3>
                        </div>
                    </div>

                    <div class="u-padding">
                        <div class="wizard__help-text">
                            {{ trans('shop.wizard.live-chat-text') }}
                            <a
                                href="#" >
                                {{ trans('shop.wizard.live-chat-contact') }}
                            </a>
                        </div>

                        <div class="u-separator-line"/>

                        <div class="wizard__steps wizard__steps--cart">
                            <router-link
                                :to="{ name: 'category.index' }"
                            >
                                <button class="btn btn--ghost btn--continue btn--full-width-responsive">
                                    {{ trans('shop.wizard.navigation.continue-shopping') }}
                                </button>
                            </router-link>

                            <button
                                :disabled="!isValidQuantity"
                                class="btn btn--primary btn--full-width-responsive"
                                @click="$router.push({ name: 'checkoutShipping' })"
                            >
                                {{ trans('shop.wizard.navigation.step-2') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ApiMixin from '../../mixins/api.js';
import pageMixin from '../../mixins/page.js';

import WizardSteps from '../../components/checkout/WizardSteps.vue';
import ProductSearchPartial from '../../components/ProductSearchPartial.vue';
import CartItemRowPartial from '../../components/checkout/CartItemRowPartial.vue';

export default {
    components: {
        WizardSteps,
        ProductSearchPartial,
        CartItemRowPartial
    },
    mixins: [pageMixin, ApiMixin],
    props: {},
    metaInfo: {
        title: trans('page.checkout.cart.title')
    },
    computed: {
        ...mapGetters({
            cartItems: 'cart/items',
            cartTotalPrice: 'cart/totalPrice',
            isValidQuantity: 'cart/isValidQuantity'
        })
    },
    /**
     * Called after the instance has been mounted.
     */
    mounted() {
        this.finishLoading();
        this.scrollToTop();
        this.updateValidQuantity(true);
    },
    methods: {
        ...mapActions({
            updateValidQuantity: 'cart/updateValidQuantity'
        })
    }
};
</script>
