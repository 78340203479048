import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=64f5b750&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/kysapp/domains/webshop.krandp.com/releases/20220613115153/resources/assets/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64f5b750')) {
      api.createRecord('64f5b750', component.options)
    } else {
      api.reload('64f5b750', component.options)
    }
    module.hot.accept("./PageHeader.vue?vue&type=template&id=64f5b750&", function () {
      api.rerender('64f5b750', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "scripts/components/PageHeader.vue"
export default component.exports